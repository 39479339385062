import {Collapse, Progress, Tag} from "antd";
import React from "react";
import {
    bold,
    boldBlue,
    boldGreen,
    boldOrange,
    boldRed,
    getCategoryTag,
    getDescriptionTag,
} from "../../Utils/HtmlUtils";
import {roundToFixed, roundToFixed2} from "../../API/Utils";


const {Panel} = Collapse;

type ShipmentViewProps = {
    shipmentData: any,
    positive: boolean,
    label: string,
    sort?: boolean
}


const ShipmentView: React.SFC<ShipmentViewProps> = (props) => {

    let numShipments = props.shipmentData.length;
    let totalShippingCost = 0;
    let totalNumItems = 0;
    let totalShippedWeight = 0;
    let totalShippedVolume = 0;
    let cartonPrice = 0;

    props.shipmentData.forEach((shipment: any) => {
        totalShippingCost += shipment.shipment_price;
        totalNumItems += shipment.num_items;
        totalShippedWeight += shipment.actual_total_shipment_weight_lbs;
        totalShippedVolume += shipment.box_volume_cbft;
        cartonPrice += shipment.carton_cost;

    })

    // Sort shipments by price descending
    if (props.sort) {
        props.shipmentData.sort((a: any, b: any) => {
            return b.shipment_price - a.shipment_price;
        })
    }


    return (
        <div>

            <p>{boldBlue(numShipments)} {boldBlue('Shipments:')},
                {
                    props.positive ? boldGreen(' $' + roundToFixed2(totalShippingCost)) :
                        boldRed(' $' + roundToFixed2(totalShippingCost) + '')
                }
            </p>
            { cartonPrice ? <p>
                {bold('Carton Price: ')} {boldOrange('$' + roundToFixed2(cartonPrice))}
            </p> : null}
            <p>{bold(totalNumItems)} items, {bold(roundToFixed2(totalShippedWeight))} lbs, {bold(roundToFixed2(totalShippedVolume))} cb_ft</p>

            <Collapse>
                <Panel header={`${props.label} Details`} key="1" style={{}}>

                    <div>
                        <table>
                            <thead>

                            <tr
                                style={{
                                    fontSize: '15px',
                                    textAlign: 'center'
                                }}
                            >
                                <th>Shipment</th>
                                <th>Cargo</th>
                            </tr>

                            </thead>


                            <tbody>

                            {props.shipmentData.map((shipment: any, index: number) => {
                                let shipViaClean;
                                let shipViaTagColor;

                                if (shipment.ship_method) {
                                    shipment.ship_via = shipment.ship_method
                                }

                                if (shipment.ship_via.includes('Large Flat Rate')) {
                                    shipViaClean = 'USPS LFR'
                                    shipViaTagColor = 'orange'
                                } else if (shipment.ship_via.includes('Medium Flat Rate')) {
                                    shipViaClean = 'USPS MFR'
                                    shipViaTagColor = 'orange'
                                } else if (shipment.ship_via.includes('Small Flat Rate')) {
                                    shipViaClean = 'USPS SFR'
                                    shipViaTagColor = 'orange'

                                } else if (shipment.ship_via.includes('FedEx')) {
                                    shipViaClean = 'FedEx Ground'
                                    shipViaTagColor = 'purple'

                                } else if (shipment.ship_via.includes('Outside')) {
                                    shipViaClean = 'Ship Outside System'
                                    shipViaTagColor = 'red'
                                }
                                else {
                                    shipViaClean = 'USPS PM'
                                    shipViaTagColor = 'blue'
                                }


                                return (
                                    <tr key={index} style={{
                                        fontSize: '12px',
                                        textAlign: 'center',
                                        backgroundColor: index % 2 === 0 ? '#d4ebf2' : 'white'

                                    }}>


                                        <td style={{
                                            border: '1px solid black',
                                            fontWeight: 'bold'
                                        }}>
                                            <p>
                                                {props.positive ? boldGreen(' $' + roundToFixed2(shipment.shipment_price)) :
                                                    boldRed(' $' + roundToFixed2(shipment.shipment_price) + '')
                                                }
                                            </p>
                                            <p>
                                                {boldOrange(' $' + roundToFixed2(shipment.carton_cost))}
                                            </p>

                                            <p>
                                                {
                                                    props.label === 'Actual' ? <a
                                                        href={`https://med.deposco.com/deposco/entity/detail;id=${shipment.shipment_id};entity=Shipment;bk=${shipment.shipment_id}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {shipment.shipment_id}
                                                    </a> : <span>Simulated_{+index + 1}</span>
                                                }
                                            </p>


                                            {
                                                shipment.ship_via.includes('Flat Rate') ? <div>

                                                    <Tag color={shipViaTagColor}>{shipViaClean}</Tag>


                                                </div> : <div>
                                                    <p><Tag color={shipViaTagColor}>{shipment.box_name}</Tag></p>
                                                    <p><Tag color={shipViaTagColor}>{shipViaClean}</Tag></p>
                                                </div>

                                            }

                                            <div
                                                style={{
                                                    marginTop: '10px'
                                                }}
                                            >
                                                <p>{bold('Fill Rate:')}</p>
                                                <Progress
                                                    percent={+roundToFixed(100 * shipment.box_fill_rate, 0)}
                                                    status={'active'}
                                                    type={'dashboard'}
                                                    width={50}
                                                    format={p => `${roundToFixed(100 * shipment.box_fill_rate, 0)}%`}
                                                    strokeColor={{
                                                        '100%': '#108ee9',
                                                        '0%': '#87d068',
                                                    }}
                                                />


                                            </div>

                                        </td>

                                        <td style={{
                                            border: '1px solid black',
                                            fontWeight: 'bold'
                                        }}>
                                            <p>
                                                {shipment.num_items} items
                                            </p>
                                            <p>
                                                {roundToFixed2(shipment.actual_total_shipment_weight_lbs)} lbs, {roundToFixed2(shipment.cargo_volume_cbft)} cb_ft
                                            </p>

                                            <p>
                                                {bold('Tags: ')}
                                            </p>

                                            <p>
                                                {shipment.description_tags ? Object.keys(shipment.description_tags).map((tag: string, index: number) => {
                                                    let tagCount = shipment.description_tags[tag];
                                                    if (tagCount > 0) {
                                                        return getDescriptionTag(tag, tagCount, index)

                                                    } else {
                                                        return null
                                                    }

                                                }) : null}
                                            </p>


                                            <p> {bold('Categories: ')}</p>
                                            <p>
                                                {shipment.categories ? Object.keys(shipment.categories).map((category: string, index: number) => {

                                                    let categoryCount = shipment.categories[category];
                                                    if (categoryCount > 0) {
                                                        return getCategoryTag(category, categoryCount, index)

                                                    } else {
                                                        return null
                                                    }


                                                }) : null}
                                            </p>


                                        </td>


                                    </tr>
                                )
                            })}

                            </tbody>

                        </table>

                    </div>
                </Panel>
            </Collapse>

        </div>
    )
}

export default ShipmentView

import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import {Button, Icon, Progress, Table, Tag} from "antd";
import {
    authSuccessful,
    backendCall,
    formattedPackerName, roundToFixed,
    roundToFixed2,
    utcToLocal
} from "../../API/Utils";
import useAuthUser from "../../Hooks/auth/useAuthUser";
import {
    bold,
    boldBlue,
    boldGreen,
    boldRed, getAlgorithmTag,
    getCategoryTag,
    getDescriptionTag, getStatusTag
} from "../../Utils/HtmlUtils";
import ShipmentView from "../../Components/Shared/ShipmentView";
import ItemsView from "../../Components/Shared/ItemsView";
import MedusaOrderButton from "../../Components/ActionButtons/MedusaOrderButton";


const RequestHistoryContainer = () => {


    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const {authUser, authPending} = useAuthUser()

    const pageSize = 60
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(100);
    const [sortDirection, setSortDirection] = useState('desc');
    const [sortColumn, setSortColumn] = useState('time_of_request_utc');
    const [refreshCounter, setRefreshCounter] = useState(0);


    const columnTitle = (columnTitle: string, columnTooltip: string) => {
        return columnTitle
    }

    const tableColumns = [
        {
            title: columnTitle('Order', ''),
            dataIndex: 'order_id',
            key: 'order_id',
            width: '40%',
            render: (orderId: any, record: any) => {
                let solution = record.active_solution_details

                return <div>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '2%',
                            marginBottom: '2%'
                        }}
                    >
                        <div>
                            <p>
                                {bold('Tote: ')}{boldBlue(record.tote_id)}
                            </p>
                        </div>

                        <div>
                            <MedusaOrderButton
                                orderId={record.order_id}
                                hideOrderId={false}
                            />
                        </div>
                        <div
                            style={{
                                marginLeft: '5%'
                            }}
                        >
                            <Button
                                onClick={() => {
                                    navigator.clipboard.writeText(orderId)
                                }}
                            >
                                <Icon type={'copy'}/>Copy Order
                            </Button>
                        </div>
                    </div>
                    <p>{bold('Destination:')} {record.destination_state} (Zone {record.rate_zone})</p>
                    <p>{bold('Tote Scanned at:')} {utcToLocal(record.order_date).toLocaleString()}</p>

                    <p>
                        {bold('Description Tags:')}
                    </p>
                    {
                        Object.keys(record.description_tags)
                            .map((tag: any, index: number) => getDescriptionTag(tag, record.description_tags[tag], index))
                    }

                    <p>
                        {bold('Categories:')}
                    </p>
                    {
                        Object.keys(record.categories)
                            .map((category: any, index: number) => getCategoryTag(category, record.categories[category], index))
                    }

                    <div
                        style={{
                            marginTop: '5%',
                        }}
                    >

                        {solution ? <ItemsView
                            itemsData={solution.theoretical_shipment_assignment.items}
                            orderValue={record.order_total_usd}
                        /> : null}

                    </div>

                </div>
            }
        },

        {
            title: columnTitle('Solution', ''),
            dataIndex: 'shipments_actual',
            key: 'solution',
            width: '40%',
            render: (shipmentsActual: any, record: any) => {
                let solution = record.active_solution_details
                let solution_id = solution.solution_id
                let request_id = record.request_id
                let theoretical_shipment_assignment = solution.theoretical_shipment_assignment
                let algorithm_name = theoretical_shipment_assignment ? theoretical_shipment_assignment.algorithm_name : ''


                return <div>
                    <div>
                        <div
                            style={{
                                marginBottom: '2%',
                                marginLeft: '5%'
                            }}
                        >
                            <img
                                src={record.packer_profile?.photo_url}
                                alt="Profile Pic"
                                style={{
                                    width: "85px",
                                    borderRadius: "50%",
                                }}
                            />
                        </div>
                        <p>
                            {bold('Packed By:')} {formattedPackerName(record.packer_profile)}
                        </p>
                    </div>

                    {solution_id ? <p>
                        {bold('Solution: ')}
                        <a
                            href={`/pack_solution/${solution_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {solution_id.substring(0, 8)}
                        </a>
                    </p> : null}

                    {request_id ? <p>
                        {bold('Request: ')}
                        <a
                            href={`/replay_request/${request_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {request_id.substring(0, 8)}
                        </a>
                    </p> : null}

                    <p>
                        {bold('Algorithm: ')} {getAlgorithmTag(algorithm_name)}
                    </p>

                    <p>{bold('Savings: ')}
                        {record.savings > 0 ? boldGreen('$' + roundToFixed2(record.savings)) : boldRed('$' + roundToFixed2(record.savings))}
                        {record.savings > 0 ? boldGreen(' (' + roundToFixed2(record.savings_pctg) + '%)') : boldRed(' (' + roundToFixed2(record.savings_pctg) + '%)')}
                    </p>

                    {solution ? <ShipmentView
                        shipmentData={solution.theoretical_shipment_assignment.shipments}
                        positive={record.savings > 0}
                        label={'Solution'}
                        sort={true}
                    /> : null}

                </div>
            }

        },

        {
            title: columnTitle('Packing Progress', ''),
            dataIndex: 'shipments_actual',
            key: 'progress',
            width: '20%',
            render: (shipmentsActual: any, record: any) => {
                let lastActionParams = record.last_action_params
                let shipmentCheckouts = record.shipment_checkouts
                let totalNumShipments = +lastActionParams.total_number_of_shipments;
                let numCheckouts = shipmentCheckouts ? shipmentCheckouts.length : 0


                let itemsScanned = +lastActionParams.total_solution_quantity_scanned
                let itemsTotal = +lastActionParams.total_solution_quantity_to_scan

                let itemStatus: "active" | "success" | "exception" | "normal" | undefined;

                if (itemsScanned === itemsTotal) {
                    itemStatus = 'success'
                } else if (itemsScanned === 0) {
                    itemStatus = 'exception'
                } else {
                    itemStatus = 'active'
                }


                let timeOfPackingStart = utcToLocal(record.first_action_time_utc)
                let currentDate = new Date()
                let timeOfPackingEnd = record.last_action_time_utc ? utcToLocal(record.last_action_time_utc) : null

                let status = 'SHIPPING IN PROGRESS'

                if (numCheckouts === totalNumShipments) {
                    status = 'SHIPPED COMPLETELY';
                    itemsScanned = itemsTotal;

                } else if (itemsScanned < itemsTotal) {
                    status = 'PACKING IN PROGRESS'
                }

                let itemScannedPercent = (itemsScanned / itemsTotal) * 100
                let lengthOfPackingSeconds = Math.round((currentDate.getTime() - timeOfPackingStart.getTime()) / 1000)

                if (status === 'SHIPPED COMPLETELY') {
                    lengthOfPackingSeconds = Math.round((timeOfPackingEnd.getTime() - timeOfPackingStart.getTime()) / 1000)
                }

                // Format the length of packing in hours, minutes, and seconds
                let hours = Math.floor(lengthOfPackingSeconds / 3600);
                let minutes = Math.floor((lengthOfPackingSeconds % 3600) / 60);
                let seconds = lengthOfPackingSeconds % 60;
                let lengthOfPacking = `${hours}h ${minutes}m ${seconds}s`;


                return <div>

                    <div
                        style={{
                            textAlign: 'center',
                            marginBottom: '5%'
                        }}
                    >
                        <Progress
                            percent={+roundToFixed(itemScannedPercent, 0)}
                            status={itemStatus}
                            type={'dashboard'}
                            width={90}
                            format={p => `${roundToFixed2(itemScannedPercent)}%`}
                            strokeColor={{
                                '100%': '#108ee9',
                                '0%': '#87d068',
                            }}
                        />
                    </div>


                    <p>
                        {bold('Status: ')} {getStatusTag(status, 0)}
                    </p>
                    <p>
                        {bold('Started: ')} {timeOfPackingStart.toLocaleString()}
                    </p>

                    {status === 'SHIPPED COMPLETELY' ? <p>
                        {bold('Finished: ')} {timeOfPackingEnd.toLocaleString()}
                    </p> : null}
                    <p>
                        {bold('Duration: ')} {lengthOfPacking}
                    </p>

                    <div>
                        <p>
                            {bold('Items Scanned:')} {lastActionParams.total_solution_quantity_scanned} / {lastActionParams.total_solution_quantity_to_scan}
                        </p>
                    </div>


                    <div>
                        <p>
                            {bold('Completed Shipments:')} {numCheckouts} / {totalNumShipments}
                        </p>
                        <div
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            {
                                shipmentCheckouts.map((checkout: any, index: number) => {
                                    return <a
                                        key={index}
                                        target={'_blank'}
                                        rel="noopener noreferrer"
                                        href={`https://med.deposco.com/deposco/entity/detail;id=${checkout.deposco_shipment_id};entity=Shipment;bk=${checkout.deposco_shipment_id}`}
                                    >
                                        <Tag
                                            color={'cyan'}
                                            style={{
                                                marginTop: '1%',
                                                marginBottom: '1%',
                                                cursor: 'pointer',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {`Simulated_${+checkout.shipment_id + 1}`} {' -> '} {checkout.deposco_shipment_id}
                                        </Tag></a>
                                })
                            }
                        </div>

                    </div>

                </div>
            }
        }
    ];

    useEffect(() => {
        if (authSuccessful(authUser, authPending)) {
            fetch(pageNumber, sortDirection, sortColumn)
        }

        setTimeout(() => {
            setRefreshCounter(refreshCounter + 1)
        }, 300000)

    }, [authPending, authUser, pageNumber, sortDirection, sortColumn, refreshCounter])


    const handleChange = (pagination: any, filters: any, sorter: any) => {
        setData([]);
        const pageNumber = pagination.current
        let _sortDirection = sortDirection
        let _sortColumn = sortColumn

        if (sorter.order) {
            if (sorter.order === 'descend') {
                _sortDirection = 'desc'
            } else {
                _sortDirection = 'asc'
            }
        }

        if (sorter.columnKey) {
            _sortColumn = sorter.columnKey

        }

        let runExtraFetch = sortColumn === _sortColumn && sortDirection === _sortDirection

        setSortColumn(_sortColumn)
        setSortDirection(_sortDirection)
        setPageNumber(pageNumber)

        if (runExtraFetch) {
            fetch(pageNumber, _sortDirection, _sortColumn)
        }


    }


    const fetch = (_pageNumber: number, _sortDirection: string, _sortColumn: string) => {
        backendCall('/get-optimised-requests', {
            is_test: '0',
            user_id: authUser.packer_username,
            page_number: _pageNumber,
            page_size: pageSize,
            sort_direction: _sortDirection,
            sort_column: _sortColumn,
        }, r => r).then((r: any) => {

            if (r['result'] && r['result']['requests']) {
                setData(r['result']['requests'].map((item: any, index: number) => {
                    return {
                        key: index,
                        ...item
                    }
                }))
                setTotalItems(r['result']['total_num_request'])
            }

            setLoading(false);

        })
    };


    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    textAlign: 'center',
                }}
            >

                <div
                    style={{
                        width: '90%'
                    }}
                >
                    <Table
                        dataSource={data}
                        columns={tableColumns}
                        pagination={{
                            pageSize: pageSize,
                            total: +totalItems,
                            position: 'both',
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} historical requests`

                        }}
                        loading={loading}
                        onChange={handleChange}
                        scroll={{
                            y: 800,
                            scrollToFirstRowOnChange: true
                        }}

                    />
                </div>
            </div>

        </div>

    )
}
export default withRouter(RequestHistoryContainer);

import {backendCall} from "../API/Utils";
import {AuthService} from "./AuthService";


export class PackingStationService {
    private broadcastEndpoint: string = '/broadcast';

    public static stationsWithHardware: Array<string> = [
        'STATION_2',
        'STATION_3',
        'STATION_5',
        'STATION_8',
        'STATION_9',
        'STATION_10',
        'STATION_11',
        'STATION_12',
        'STATION_13',
        'STATION_14',
        'STATION_15',
        'STATION_16',
        'STATION_17',
        'STATION_18',
        'STATION_20',
        'STATION_36',

    ].sort();

    private authService: AuthService;
    private stationId: string;

    private actionCount: number = 0;


    public constructor(authService: AuthService) {
        this.authService = authService
        this.stationId = this.authService.getPackingStationId();
    }

    public restartLights(callback: (data: any) => void = (data: any) => {
    }) {
        this._call({
            is_test: '0',
            client_id: this.stationId,
            station_id: this.stationId,
            box_name: 'box_name',
            box_station_id: 0,
            item_quantity: 0,
            item_remaining_quantity: -1,
            item_scanned_quantity: 0,
            item_name: '',
            item_upc: '',
            error_message: ''

        }, callback)
    }


    public unscanItemSuccess(
        shipmentId: number,
        originalQuantity: number,
        scannedQuantity: number,
        itemType: string,
        itemUpc: string,
        callback: (data: any) => void = (data: any) => {
        }
    ) {
        this._call({
            is_test: '0',
            client_id: this.stationId,
            station_id: this.stationId,
            box_name: 'box_name',
            box_station_id: shipmentId,
            item_quantity: originalQuantity,
            item_remaining_quantity: 1,
            item_scanned_quantity: scannedQuantity - 1,
            item_name: itemType,
            item_upc: itemUpc,
            error_message: ''

        }, callback)

    }

    public resetScanForAllItems(
        shipmentId: number,
        originalQuantity: number,
        callback: (data: any) => void = (data: any) => {
        }
    ) {
        this._call({
            is_test: '0',
            client_id: this.stationId,
            station_id: this.stationId,
            box_name: 'box_name',
            box_station_id: shipmentId,
            item_quantity: originalQuantity,
            item_remaining_quantity: 0,
            item_scanned_quantity: 0,
            item_name: 'RESET',
            item_upc: 'ALL',
            error_message: ''
        }, callback)

    }

    public scanAllItems(
        shipmentId: number,
        originalQuantity: number,
        callback: (data: any) => void = (data: any) => {
        }
    ) {
        this._call({
            is_test: '0',
            client_id: this.stationId,
            station_id: this.stationId,
            box_name: 'box_name',
            box_station_id: shipmentId,
            item_quantity: originalQuantity,
            item_remaining_quantity: 1,
            item_scanned_quantity: originalQuantity - 1,
            item_name: 'SCAN',
            item_upc: 'ALL',
            error_message: ''

        }, callback)

    }


    public overScannedItem(
        shipmentId: number,
        originalQuantity: number,
        scannedQuantity: number,
        itemType: string,
        itemUpc: string,
        callback: (data: any) => void = (data: any) => {
        }
    ) {
        this._call({
            is_test: '0',
            client_id: this.stationId,
            station_id: this.stationId,
            box_name: 'box_name',
            box_station_id: shipmentId,
            item_quantity: originalQuantity,
            item_remaining_quantity: 0,
            item_scanned_quantity: scannedQuantity,
            item_name: itemType,
            item_upc: itemUpc,
            error_message: ''

        }, callback)

    }

    public moveItemSuccess(
        shipmentId: number,
        boxFromId: number,
        boxToId: number,
        quantityAtOrigin: number,
        quantityAtDestination: number,
        itemType: string,
        itemUpc: string,
        callback: (data: any) => void = (data: any) => {
        }
    ) {

        this._call({
            is_test: '0',
            client_id: this.stationId,
            station_id: this.stationId,
            box_to_id: boxToId,
            box_from_id: boxFromId,
            box_name: 'box_name',
            ui_command: 'move_item',
            box_station_id: shipmentId,
            item_quantity: quantityAtOrigin,
            item_remaining_quantity: 1,
            item_scanned_quantity: quantityAtDestination,
            item_name: itemType,
            item_upc: itemUpc,
            error_message: ''

        }, callback)

    }

    public scanItemSuccess(
        shipmentId: number,
        originalQuantity: number,
        scannedQuantity: number,
        itemType: string,
        itemUpc: string,
        callback: (data: any) => void = (data: any) => {
        }
    ) {
        this._call({
            is_test: '0',
            client_id: this.stationId,
            station_id: this.stationId,
            box_name: 'box_name',
            box_station_id: shipmentId,
            item_quantity: originalQuantity,
            item_remaining_quantity: 1,
            item_scanned_quantity: scannedQuantity - 1,
            item_name: itemType,
            item_upc: itemUpc,
            error_message: ''

        }, callback)
    }

    public scanItemFailure(
        itemUpc: string,
        errorMessage: string,
        callback: (data: any) => void = (data: any) => {
        }
    ) {
        this._call({
            is_test: '0',
            client_id: this.stationId,
            station_id: this.stationId,
            box_name: 'box_name',
            box_station_id: 0,
            item_quantity: 0,
            item_remaining_quantity: 0,
            item_scanned_quantity: 0,
            item_name: 0,
            item_upc: itemUpc,
            error_message: errorMessage

        }, callback)
    }


    private _call(params: any, callback: (data: any) => void = (data: any) => {
    }) {
        this.actionCount += 1;
        params = {
            ...params,
            action_count: this.actionCount
        }

        if (PackingStationService.stationsWithHardware.includes(this.authService.getPackingStationId())) {
            backendCall(this.broadcastEndpoint, params, r => r).then((r: any) => {
                try {
                    callback(r);
                } catch (e: any) {
                    console.error('Something happened when calling the broadcast endpoint...')
                    console.log(e)
                    // alert(e)
                }
            })

        } else {
            callback({})
        }

    }

}

import {Item} from "./Item";
import {Box, ShippingBox, VirtualBox} from "./Box";
import {ItemOperationResult} from "./Operation/ItemOperationResult";
import {ItemOperationStatus} from "./Operation/ItemOperationStatus";
import {PackingStationService} from "../Service/PackingStationService";
import {ItemOperation} from "./Operation/ItemOperation";
import {ScanItemOperation} from "./Operation/ScanItemOperation";
import {ScanAllItemsOperation} from "./Operation/ScanAllItemsOperation";
import {ResetScanForAllItemsOperation} from "./Operation/ResetScanForAllItemsOperation";
import {ResetScanForItemInShipmentOperation} from "./Operation/ResetScanForItemInShipmentOperation";
import {MoveItemOperation} from "./Operation/MoveItemOperation";
import {AuthService} from "../Service/AuthService";
import {ActionLoggingService} from "../Service/ActionLoggingService";

export class ItemManager {
    private orderId: string;
    private toteId: string;
    private requestId: string;
    private solutionId: string;

    private itemTypes: Set<string> = new Set<string>();
    private itemMap: Map<string, Item> = new Map<string, Item>();
    private items: Array<Item> = new Array<Item>();

    private scannedBoxes: Set<string> = new Set<string>();

    private shipmentIdToBoxMap: Map<number, VirtualBox> = new Map<number, VirtualBox>(); // shipmentId -> VirtualBox
    private itemTypeQuantityMap: Map<string, number> = new Map<string, number>(); // itemType -> quantity
    private upcToItemTypeMap: Map<string, string> = new Map<string, string>(); // upc -> itemType
    private itemTypeToUpcMap: Map<string, string> = new Map<string, string>(); // itemType -> upc

    private lastOperationSuccess: boolean = false;
    private lastOperationErrors: string[] = [];
    private lastOperationInfoMessages: string[] = [];

    private solutionBody: any;
    private initilalized: boolean;

    private setShipments: any;
    private setLoading: any;
    private setItemOperationResult: any;
    private setScannedItemUpc: any;
    private authService: AuthService;
    private packingStationService: PackingStationService;
    private actionLoggingService: any;


    public constructor(
        setShipments: any,
        setLoading: any,
        setItemOperationResult: any,
        setScannedItemUpc: any,
        authUser: any,
        orderId: string = '',
        toteId: string = '',
        requestId: string = '',
        solutionId: string = ''
    ) {
        this.setShipments = setShipments;
        this.setLoading = setLoading;
        this.setItemOperationResult = setItemOperationResult;
        this.setScannedItemUpc = setScannedItemUpc;
        this.orderId = orderId;
        this.toteId = toteId;
        this.requestId = requestId;
        this.solutionId = solutionId;
        this.authService = new AuthService(authUser);

        this.initilalized = false;
        this.packingStationService = new PackingStationService(this.authService);
        this.actionLoggingService = new ActionLoggingService(this);
    }

    public getActionLoggingService(): ActionLoggingService {
        return this.actionLoggingService;
    }

    public setSolutionBody(_solutionBody: any) {
        this.solutionBody = _solutionBody;
    }

    public getAuthService(): AuthService {
        return this.authService;
    }


    public buildUpcMap() {
        this.upcToItemTypeMap = new Map<string, string>();
        let rawMap = this.solutionBody.upc_to_item_mapping;

        Object.keys(rawMap).forEach((upc: string) => {
            let itemType = rawMap[upc][0];
            this.upcToItemTypeMap.set(upc, itemType);
            this.itemTypeToUpcMap.set(itemType, upc);
        });
    }

    public initialize() {
        this.buildItemQuantityMap();
        this.buildItemTypes();
        this.buildBoxesAndItems();
        this.buildOriginalItemsForVirtualBoxes();
        this.buildUpcMap();

        this.updateShipments();

        this.initilalized = true;
        this.setLoading(false);
    }

    getUserName(): string {
        return this.authService.getUsername();
    }

    getPackingStationId(): string {
        return this.authService.getPackingStationId();
    }


    private buildBoxesAndItems() {
        let shipmentBodies = this.solutionBody.theoretical_shipment_assignment.shipments;

        shipmentBodies.forEach((shipment: any, index: number) => {

            let newShipment = {
                ...shipment,
                key: index,
                item_quantities_original: shipment.item_quantities,
                item_quantities_scanned: shipment.item_quantities.map((i: any) => {
                    return [i[0], 0]
                }),
                solution_id: this.solutionBody.solution_id,
                scanAllModalOpen: false,
                resetScanModalOpen: false
            }

            let shipmentIdString = shipment.shipment_id;
            let shipmentId = parseInt(shipmentIdString);

            let dictItem = this.solutionBody.box_to_ship_method[shipmentIdString];
            let boxName = dictItem.box_name;
            let shippingMethod = dictItem.ship_method;
            let boxWeight = dictItem.box_weight_lbs;

            let shippingBox = new ShippingBox(
                this.itemTypes,
                this.itemMap,
                newShipment,
                boxName,
                boxWeight,
                shippingMethod
            );

            let virtualBox = new VirtualBox(
                this.itemTypes,
                this.itemMap,
                shippingBox,
                newShipment
            );

            this.shipmentIdToBoxMap.set(shipmentId, virtualBox);
        })

        Object.keys(this.solutionBody.item_to_box_quantity_matrix).forEach((itemType: string) => {
            let splitAmongItems = this.solutionBody.item_to_box_quantity_matrix[itemType];
            let itemDetails = this.solutionBody.item_details_map[itemType];

            Object.keys(splitAmongItems).forEach((shipmentIdString: string) => {
                let shipmentId = parseInt(shipmentIdString);
                let itemQuantityInGivenShipment = splitAmongItems[shipmentIdString];

                for (let i = 0; i < itemQuantityInGivenShipment; i++) {
                    let item = new Item(
                        itemType,
                        this.getVirtualBox(shipmentId),
                        itemDetails.width_inches,
                        itemDetails.height_inches,
                        itemDetails.length_inches,
                        itemDetails.weight_lbs,
                        itemDetails.volume_cube_ft,
                        itemDetails.price_usd,
                        itemDetails.category,
                        itemDetails.brand_name,
                        itemDetails.variant_name,
                        itemDetails.image_thumbnail,
                        itemDetails.description_tags,
                        itemDetails.product_name
                    );
                    this.items.push(item);
                    this.itemMap.set(item.getId(), item);
                }
            })

        });
    }

    public addScannedBox(boxId: string) {
        this.scannedBoxes.add(boxId);
    }

    public getRandomItemOfType(itemType: string): Item | null {
        let itemsOfType = this.items.filter((item) => {
            return item.getType() === itemType;
        })

        if (itemsOfType.length === 0) {
            return null;
        } else {
            return itemsOfType[0];
        }
    }

    public removeScannedBox(boxId: string) {
        this.scannedBoxes.delete(boxId);
    }

    public getScannedBoxes(): Set<string> {
        return this.scannedBoxes;
    }

    public clearScannedBoxes() {
        this.scannedBoxes.clear();
    }

    public isBoxScanned(boxId: string): boolean {
        return this.scannedBoxes.has(boxId);
    }

    public buildItemTypes() {
        this.itemTypeQuantityMap.forEach((quantity, itemType) => {
            this.itemTypes.add(itemType);
        });
    }

    private buildItemQuantityMap() {
        Object.keys(this.solutionBody.item_quantity_map).forEach((itemType: string) => {
            let quantity = this.solutionBody.item_quantity_map[itemType];
            this.itemTypeQuantityMap.set(itemType, quantity);
        });
    }


    public buildOriginalItemsForVirtualBoxes() {
        this.items.forEach((item) => {
            let originShipmentId = item.getOriginShipmentId();
            let originBox = this.getVirtualBox(originShipmentId);
            originBox.addItemToOriginal(item);
        })
    }

    public getVirtualBox(shipmentId: any): VirtualBox {
        return this.shipmentIdToBoxMap.get(+shipmentId) as VirtualBox;

    }

    public getShippingBox(shipmentId: any): ShippingBox {
        return this.getVirtualBox(shipmentId).getShippingBox();
    }

    public getLastOperationSuccess(): boolean {
        return this.lastOperationSuccess;
    }

    public getLastOperationErrors(): string[] {
        return this.lastOperationErrors;
    }

    public getLastOperationInfoMessages(): string[] {
        return this.lastOperationInfoMessages;
    }

    public getItemTypeForUPC(upc: string): string | null {
        if (!this.upcToItemTypeMap.has(upc)) {
            return null;
        }
        return this.upcToItemTypeMap.get(upc) as string;
    }

    public getUpcForItemType(itemType: string): string | null {
        if (!this.itemTypeToUpcMap.has(itemType)) {
            return null;
        }
        return this.itemTypeToUpcMap.get(itemType) as string;
    }

    public getItems(): Array<Item> {
        return this.items;
    }

    getPackingStationService(): PackingStationService {
        return this.packingStationService;
    }

    private checkCounts() {
        this.itemTypeQuantityMap.forEach((expectedCount, itemType) => {
            let realCount = 0;

            this.shipmentIdToBoxMap.forEach((virtualBox, shipmentId) => {
                let shippingBox = virtualBox.getShippingBox();
                realCount += shippingBox.getCurrentItemCount(itemType);
                realCount += virtualBox.getCurrentItemCount(itemType);

            })

            if (expectedCount !== realCount) {
                alert(`ERROR: itemType = ${itemType}, expectedCount = ${expectedCount}, realCount = ${realCount}`);
            }
        })
    }

    public updateShipments() {
        let newShipments: any[] = [];
        this.shipmentIdToBoxMap.forEach((virtualBox, shipmentId) => {
            let shippingBox = virtualBox.getShippingBox();

            newShipments.push({
                ...virtualBox.getShipmentBody(),
                item_quantities: virtualBox.getOriginalAggregatedItems(),
                item_quantities_scanned: shippingBox.getAggregatedItems(),
                key: shipmentId
            })
        })

        this.setShipments(newShipments);

    }

    public scanItemForItemType(packingStationId: string, itemType: string) {
        let upc = this.itemTypeToUpcMap.get(itemType);
        if (upc) {
            this.scanItemForUpc(packingStationId, upc);
        } else {
            alert(`ERROR: No UPC for itemType = ${itemType}`);
        }

    }

    public getNumberOfItemsUnscanned(shipmentId: any): number {
        if (!this.initilalized) {
            return 0;
        }

        let virtualBox = this.getVirtualBox(shipmentId);
        return virtualBox.getTotalCurrentItemCount();
    }

    public getNumberOfOriginalItems(shipmentId: any): number {
        if (!this.initilalized) {
            return 0;
        }

        let virtualBox = this.getVirtualBox(shipmentId);
        return virtualBox.getTotalOriginalItemCount();
    }

    public getNumberOfItemsScanned(shipmentId: any): number {
        if (!this.initilalized) {
            return 0;
        }
        let virtualBox = this.getVirtualBox(shipmentId);
        return virtualBox.getNumberOfScannedItems();
    }

    public resetScanForItemInShipment(packingStationId: string, shipmentId: any, itemType: string) {
        this.runOperation(new ResetScanForItemInShipmentOperation(
            this,
            packingStationId,
            shipmentId, itemType
        ))
    }

    public scanItemForUpc(packingStationId: string, itemUpc: string,) {
        this.runOperation(new ScanItemOperation(
            this,
            packingStationId,
            itemUpc,
        ))
    }

    public moveItem(packingStationId: string, itemUpc: string, fromShipmentId: number, toShipmentId: number) {
        this.runOperation(new MoveItemOperation(
            this,
            packingStationId,
            itemUpc,
            fromShipmentId,
            toShipmentId
        ))
    }

    public scanAllItems(packingStationId: string, shipmentId: any) {
        this.runOperation(new ScanAllItemsOperation(
            this,
            packingStationId,
            shipmentId
        ))
    }

    public resetScanForAllItems(packingStationId: string, shipmentId: any) {
        this.runOperation(new ResetScanForAllItemsOperation(
            this,
            packingStationId,
            shipmentId
        ))
    }

    public runOperation(operation: ItemOperation) {
        if (!this.initilalized) {
            alert('ItemManager not initialized');
            return;
        }

        this.setLoading(true);
        new Promise<ItemOperationResult>((resolve) => {
            operation.execute((r: ItemOperationResult) => {
                resolve(r);
            });
        }).then((r: ItemOperationResult) => {
            this.setItemOperationResult(r);

            if (r.status === ItemOperationStatus.SUCCESS) {
                this.updateShipments();
            }

        }).catch((e) => {
            console.log(e);
            alert(e);

        }).finally(() => {
            // Add a little delay to make sure the focus goes back to the input field
            // Also we want to avoid double scans from bad scanners
            setTimeout(() => {
                this.setLoading(false);
                this.setScannedItemUpc('');
                this.checkCounts();
            }, 1)

        })


    }

    getItemForId(itemId: string): Item | null {
        if (this.itemMap.has(itemId)) {
            return this.itemMap.get(itemId) as Item;
        } else {
            return null;
        }
    }

    getItemByTypeFromBox(box: Box, itemType: string): Item | null {
        let itemSet = box.getItemSetForType(itemType);

        if (itemSet.size === 0) {
            return null;
        } else {
            let itemId = itemSet.values().next().value;
            return this.getItemForId(itemId);

        }
    }

    public isInitialized(): boolean {
        return this.initilalized;
    }

    public getNumShipments(): number {
        return this.shipmentIdToBoxMap.size;
    }

    public getShipmentIdList(): number[] {
        return Array.from(this.shipmentIdToBoxMap.keys());
    }

    public isUpcValid(upc: string): boolean {
        return this.upcToItemTypeMap.has(upc);
    }

    public itemInShipmentForUpc(shipmentId: number, upc: string): boolean {
        if (!this.isUpcValid(upc)) {
            return false;
        } else {

            let itemType = this.upcToItemTypeMap.get(upc) as string;
            let virtualBox = this.getVirtualBox(shipmentId);
            let shippingBox = virtualBox.getShippingBox();

            return shippingBox.getItemSetForType(itemType).size > 0 || virtualBox.getItemSetForType(itemType).size > 0;

        }
    }

    public getFieldForShipmentId(shipmentId: number, fieldName: string): any {
        let virtualBox = this.getVirtualBox(shipmentId);
        return virtualBox.getField(fieldName);
    }

    public updateFieldForShipmentId(shipmentId: number, fieldName: string, value: any, runUpdateImmediately: boolean = true) {
        let virtualBox = this.getVirtualBox(shipmentId);
        virtualBox.updateField(fieldName, value);

        if (runUpdateImmediately) {
            this.updateShipments();
        }
    }

    public overrideShipMethodForShipmentId(shipmentId: number, newShipMethod: string) {
        this.updateFieldForShipmentId(shipmentId, 'ship_method', newShipMethod, true);
    }

    overrideBoxTypeForShipmentId(shipmentId: number, newBoxName: string) {
        this.updateFieldForShipmentId(shipmentId, 'box_name', newBoxName, true);
    }

    getTotalQuantityScanned() {
        let total = 0;
        this.shipmentIdToBoxMap.forEach((virtualBox) => {
            total += virtualBox.getNumberOfScannedItems();
        })

        return total;
    }

    getTotalQuantityRemaining() {
        let total = 0;
        this.shipmentIdToBoxMap.forEach((virtualBox) => {
            total += virtualBox.getNumberOfUnscannedItems();
        })

        return total;
    }

    getTotalQuantityToScan() {
        return this.getTotalQuantityScanned() + this.getTotalQuantityRemaining();
    }

    getSolutionId(): string {
        return this.solutionId;
    }

    getRequestId(): string {
        return this.requestId;
    }

    getToteId(): string {
        return this.toteId;
    }

    getOrderId(): string {
        return this.orderId;
    }

    setOrderId(orderId: string) {
        this.orderId = orderId;
    }

    setToteId(toteId: string) {
        this.toteId = toteId;
    }

    setRequestId(requestId: string) {
        this.requestId = requestId;
    }

    setSolutionId(solutionId: string) {
        this.solutionId = solutionId;
    }


    getNumberOfShipments() {
        return this.shipmentIdToBoxMap.size;
    }
}
import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import {Button, Table, Spin} from "antd";
import {authSuccessful, backendCall, getRedAndGreenColorValues} from "../../API/Utils";
import useAuthUser from "../../Hooks/auth/useAuthUser";
import MedusaOrderButton from "../../Components/ActionButtons/MedusaOrderButton";


const SimulationViewContainer = () => {


    const [data, setData] = useState([]);
    const [simulationMetadata, setSimulationMetadata] = useState({} as any)
    const [loading, setLoading] = useState(true);
    const {authUser, authPending} = useAuthUser()

    const pageSize = 60;
    const totalItems = 14359;

    const [pageNumber, setPageNumber] = useState(1);
    const [sortDirection, setSortDirection] = useState('desc');
    const [sortColumn, setSortColumn] = useState('savings');


    const columnTitle = (columnTitle: string, columnTooltip: string) => {
        return columnTitle
    }

    const tableColumns = [
        {
            title: columnTitle('order_id', ''),
            dataIndex: 'order_id',
            key: 'order_id',
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
            sorter: (a: any, b: any) => a.order_id - b.order_id,
            render: (text: any, record: any) => {
                return (
                    <MedusaOrderButton orderId={text}/>
                )
            }
        },
        {
            title: columnTitle('tote_id', ''),
            dataIndex: 'tote_id',
            key: 'tote_id',
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
            render: (text: any, record: any) => {
                return (
                    <a href={`/order_id/${record.order_id}/tote_id/${record.tote_id}`} target={'_blank'}>
                        <Button
                            type={'primary'}
                        >{text}</Button>
                    </a>
                )
            }
        },
        {
            title: columnTitle('zip_code', ''),
            dataIndex: 'destination_zip_code',
            key: 'destination_zip_code',
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
        },
        {
            title: columnTitle('zone', ''),
            dataIndex: 'zone',
            key: 'zone',
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
        },
        {
            title: columnTitle('num_items', ''),
            dataIndex: 'num_items',
            key: 'num_items',
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
            sorter: (a: any, b: any) => a.num_items - b.num_items,
        },
        {
            title: columnTitle('old_cost', ''),
            dataIndex: 'old_cost',
            key: 'old_cost',
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
            sorter: (a: any, b: any) => a.old_cost - b.old_cost,
            render: (text: any, record: any) => {
                return (
                    <span style={{
                        // fontWeight: 'bold',
                    }}>${text}</span>
                )
            }
        },
        {
            title: columnTitle('new_cost', ''),
            dataIndex: 'new_cost',
            key: 'new_cost',
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
            sorter: (a: any, b: any) => a.new_cost - b.new_cost,
            render: (text: any, record: any) => {
                return (
                    <span style={{
                        // fontWeight: 'bold',
                    }}>${text}</span>
                )
            }
        },
        {
            title: columnTitle('savings', ''),
            dataIndex: 'savings',
            key: 'savings',
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
            sorter: (a: any, b: any) => a.savings - b.savings,
            render: (text: any, record: any) => {
                let number = +text
                let [r_color, g_color] = getRedAndGreenColorValues(record.savings_pctg)
                let sign = number > 0 ? '+' : '-'
                let abs_text = Math.abs(number)

                return (
                    <span style={{
                        fontWeight: 'bold',
                        color: `rgb(${r_color}, ${g_color}, 0)`
                    }}>{sign}${abs_text}</span>
                )
            }
        },
        {
            title: columnTitle('savings_pctg', ''),
            dataIndex: 'savings_pctg',
            key: 'savings_pctg',
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
            sorter: (a: any, b: any) => a.savings_pctg - b.savings_pctg,
            render: (text: any, record: any) => {
                let [r_color, g_color] = getRedAndGreenColorValues(text)

                return (
                    <span style={{
                        fontWeight: 'bold',
                        color: `rgb(${r_color}, ${g_color}, 0)`
                    }}>{text}%</span>
                )
            }
        },

    ];

    useEffect(() => {
        if (authSuccessful(authUser, authPending)) {
            fetch(pageNumber, sortDirection, sortColumn)
        }


    }, [authUser, authPending, pageNumber, sortDirection, sortColumn])


    const handleChange = (pagination: any, filters: any, sorter: any) => {
        setData([]);

        const pageNumber = pagination.current
        let _sortDirection = sortDirection
        let _sortColumn = sortColumn

        if (sorter.order) {
            if (sorter.order === 'descend') {
                _sortDirection = 'desc'
            } else {
                _sortDirection = 'asc'
            }
        }

        if (sorter.columnKey) {
            _sortColumn = sorter.columnKey

        }

        let extraFetch = _sortColumn === sortColumn && _sortDirection === sortDirection;

        setSortColumn(_sortColumn)
        setSortDirection(_sortDirection)
        setPageNumber(pageNumber)

        if(extraFetch){
            fetch(pageNumber, _sortDirection, _sortColumn)
        }

    }


    const fetch = (_pageNumber: number, _sortDirection: string, _sortColumn: string) => {
        setLoading(true)

        backendCall('/get-simulation-totes', {
            is_test: '0',
            simulation_id: 'Medusa_POC_2023_07_19',
            page_number: _pageNumber,
            page_size: pageSize,
            sort_direction: _sortDirection,
            sort_column: _sortColumn,
        }, r => r).then((r: any) => {

            if (r['result'] && r['result']['totes']) {
                setData(r['result']['totes'].map((item: any, index: number) => {
                    return {
                        ...item,
                        key: index
                    }

                }))
                setSimulationMetadata(r['result']['simulation_metadata'])

            }

            setLoading(false);

        })
    };


    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly'
                }}
            >
                <div
                    style={{
                        width: '15%',
                    }}
                >
                    <div
                        style={{
                            marginTop: '30px',
                            textAlign: 'center',
                        }}
                    >

                        <h1>USPS Order Analysis</h1>
                        <h2>START = 2023-01-01</h2>
                        <h2>END = 2023-07-01</h2>

                    </div>

                    {loading ? <Spin/> : <div>

                        <table
                            style={{
                                width: '100%',
                            }}
                        >
                            <tbody>
                            <tr>
                                <td><span style={{fontWeight: 'bold'}}>Original Shipping Cost: </span></td>
                                <td>${simulationMetadata['old_cost_all_totes']}k</td>
                            </tr>
                            <tr>
                                <td><span style={{fontWeight: 'bold'}}>Simulated Shipping Cost: </span></td>
                                <td>${simulationMetadata['new_cost_all_totes']}k</td>
                            </tr>
                            <tr>
                                <td><span style={{fontWeight: 'bold'}}>Total Savings: </span></td>
                                <td>${simulationMetadata['savings']}k</td>
                            </tr>
                            <tr>
                                <td><span style={{fontWeight: 'bold'}}>% of Savings: </span></td>
                                <td>{simulationMetadata['pctg_improvement_all_totes']}%</td>
                            </tr>
                            <tr>
                                <td><span style={{fontWeight: 'bold'}}>All Totes: </span></td>
                                <td>{simulationMetadata['num_totes_optimised']}</td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{fontWeight: 'bold'}}>Improved Totes:</span>
                                </td>
                                <td>
                                    {simulationMetadata['num_totes_improved']}
                                </td>
                            </tr>
                            <tr>
                                <td><span style={{fontWeight: 'bold'}}>% of Improved Totes: </span></td>
                                <td>{simulationMetadata['pctg_optimized_totes_improved']}%</td>
                            </tr>
                            <tr>
                                <td><span style={{fontWeight: 'bold'}}>Num Shipments Old: </span></td>
                                <td>{simulationMetadata['old_num_shipments_all_totes']}</td>
                            </tr>
                            <tr>
                                <td><span style={{fontWeight: 'bold'}}>Num Shipments New: </span></td>
                                <td>{simulationMetadata['new_num_shipments_all_totes']}</td>
                            </tr>
                            <tr>
                                <td><span style={{fontWeight: 'bold'}}>Saved Shipments: </span></td>
                                <td>{simulationMetadata['saved_shipments']}</td>
                            </tr>
                            <tr>
                                <td><span style={{fontWeight: 'bold'}}>% of Shipments Saved: </span></td>
                                <td>{simulationMetadata['pctg_shipments_reducuded_all_totes']}%</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>}
                </div>

                <div
                    style={{
                        width: '70%'
                    }}
                >
                    <Table
                        dataSource={data}
                        columns={tableColumns}
                        pagination={{
                            pageSize: pageSize,
                            total: totalItems,
                            position: 'both',
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} totes`

                        }}
                        loading={loading}
                        onChange={handleChange}
                        scroll={{
                            y: 800,
                            scrollToFirstRowOnChange: true
                        }}

                    />
                </div>
            </div>

        </div>

    )
}
export default withRouter(SimulationViewContainer);

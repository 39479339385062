import {Button, Spin} from "antd";
import * as React from "react";
import {useEffect, useRef} from "react";
import {AuthService} from "../../Service/AuthService";
import {
    backendCall,
    shortDateStringToDateObject,
    utcStringToLocalString
} from "../../API/Utils";
import {bold} from "../../Utils/HtmlUtils";
import {Bar, XAxis, Tooltip as ChartTooltip, Legend, BarChart, ResponsiveContainer, YAxis} from 'recharts';

type PackerStatsViewProps = {
    authUser: any,
    rawStats?: any,
}


const PackerStatsView: React.SFC<PackerStatsViewProps> = (props) => {
    const [loading, setLoading] = React.useState(true);
    const [timeSeriesData, setTimeSeriesData] = React.useState([]);
    const [packerStatsTotal, setPackerStatsTotal] = React.useState({} as any);
    const [packerStatsToday, setPackerStatsToday] = React.useState({} as any);
    const [statFields, setStatFields] = React.useState([]);
    const [selectedStat, setSelectedStat] = React.useState({} as any);
    const [firstOrderDate, setFirstOrderDate] = React.useState('');
    const [lastUpdateDate, setLastUpdateDate] = React.useState('' as any);

    const authServiceRef = useRef(new AuthService(props.authUser));


    useEffect(() => {
        authServiceRef.current = new AuthService(props.authUser);

        if (authServiceRef.current.isAuthenticated() && !props.rawStats) {
            backendCall('/get-packer-stats', {
                is_test: '0',
                packer_list: authServiceRef.current.getUsername(),
                user_id: authServiceRef.current.getUsername(),
            }, r => r).then((r: any) => {
                    try {
                        if (r['result']) {
                            let statsBody = r['result']['packer_stats'][authServiceRef.current.getUsername()]

                            setPackerStatsTotal(statsBody['packer_stats_total']);
                            setPackerStatsToday(statsBody['packer_stats_today']);
                            setTimeSeriesData(statsBody['time_series']);
                            setStatFields(statsBody['stat_fields']);
                            setSelectedStat(statsBody['stat_fields'][0]);
                            setFirstOrderDate(statsBody['first_order_date']);
                            setLastUpdateDate(statsBody['stats_created_at_utc'])
                        }
                    } catch (e) {
                        console.log('Error = ', e)
                        alert(`Error fetching stats: ${e}`,)
                    } finally {
                        setLoading(false);
                    }
                }
            )

        } else if (authServiceRef.current.isAuthenticated() && props.rawStats) {
            setPackerStatsTotal(props.rawStats['packer_stats_total']);
            setPackerStatsToday(props.rawStats['packer_stats_today']);
            setTimeSeriesData(props.rawStats['time_series']);
            setStatFields(props.rawStats['stat_fields']);
            setSelectedStat(props.rawStats['stat_fields'][0]);
            setFirstOrderDate(props.rawStats['first_order_date']);
            setLastUpdateDate(props.rawStats['stats_created_at_utc'])
            setLoading(false);
        }


    }, [props.authUser, props.rawStats]);

    return <div
        style={{
            textAlign: 'center',
            border: "1px solid black",
            borderRadius: "10px",
            height: "100%",
            width: "100%"
        }}
    >
        <div
            style={{
                borderBottom: '1px solid #1890ff',
                height: "57px",
                paddingTop: "1%",
            }}
        >
            {loading ? <Spin/> : <div
                style={{
                    display: 'flex',
                    width: '80%',
                    marginLeft: '10%',
                    justifyContent: 'space-around',
                    marginTop: '1%',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <h2>{bold(authServiceRef.current.getName())}</h2>
                    <h4
                        style={{
                            position: 'relative',
                            bottom: '15px',
                        }}
                    >{bold('Member Since: ')} {new Date(firstOrderDate).toLocaleDateString()}</h4>
                </div>
                <div>
                    <img
                        src={authServiceRef.current.getPictureUrl()}
                        alt={"profile"}
                        style={{
                            width: 50,
                            height: 50,
                            borderRadius: 50,
                            position: 'relative',
                            bottom: '5px',
                            border: '1px solid #1890ff',
                        }}
                    />
                </div>
            </div>}
        </div>
        <div
            style={{
                marginTop: "2%",
            }}
        >
            {loading ? <Spin/> : <div>
                <table
                    style={{
                        width: '90%',
                        marginLeft: '5%',
                    }}
                >
                    <thead>
                    <tr>
                        <th>Stat</th>
                        <th>Today</th>
                        <th>Total</th>
                    </tr>
                    </thead>

                    <tbody>
                    {
                        statFields.map((field: any, index: number) => {
                            return <tr key={index}>
                                <td>
                                    <Button
                                        onClick={() => setSelectedStat(field)}
                                        type={'link'}
                                        style={{
                                            height: '20px',
                                        }}
                                    >
                                        {bold(field['stat_name'])}
                                    </Button>
                                </td>
                                <td>{packerStatsToday[field['stat_key']]}</td>
                                <td>{packerStatsTotal[field['stat_key']]}</td>
                            </tr>
                        })
                    }


                    </tbody>

                </table>


                <div
                    style={{
                        width: '80%',
                        marginLeft: '5%',
                        marginTop: '10%',
                        marginBottom: '2%',

                    }}
                >
                    <ResponsiveContainer width="100%" height={150}>
                        <BarChart
                            data={timeSeriesData.map((stat: any) => {

                                return {
                                    date: shortDateStringToDateObject(stat['solution_date']).toDateString(),
                                    [selectedStat.stat_name]: stat[selectedStat.stat_key]
                                }
                            })}
                        >
                            <Legend/>
                            <ChartTooltip/>
                            <XAxis dataKey="date"/>
                            <YAxis dataKey={selectedStat.stat_name}/>
                            <Bar type="monotone" dataKey={selectedStat.stat_name} fill="#1890ff"/>
                        </BarChart>
                    </ResponsiveContainer>

                </div>

                <div>
                    <p>{bold('Last Updated:')} {utcStringToLocalString(lastUpdateDate)}</p>
                </div>

            </div>}

        </div>

    </div>
}

export default PackerStatsView

export const allShipmentStatuses = [
    'SHIPPING_LABEL_CREATED',
    'ARRIVED_AT_CARRIER_FACILITY',
    'DEPARTED_CARRIER_FACILITY',
    'IN_TRANSIT_ARRIVING_ON_TIME',
    'OUT_FOR_DELIVERY',
    'DELIVERED',
    'IN_TRANSIT_DELAYED',
    'RETURNED_TO_SENDER',
    'SHIPMENT_EXCEPTION',
]

export const getColorForShipmentStatus = (status: string): string | undefined => {
    let statusColorMapping: Map<string, string> = new Map<string, string>();

    statusColorMapping.set('SHIPPING_LABEL_CREATED', 'blue');
    statusColorMapping.set('SHIPPING_LABEL_CREATED', 'blue');
    statusColorMapping.set('IN_TRANSIT_ARRIVING_ON_TIME', 'green');
    statusColorMapping.set('SHIPMENT_EXCEPTION', 'red');
    statusColorMapping.set('IN_TRANSIT_DELAYED', 'orange');
    statusColorMapping.set('ARRIVED_AT_CARRIER_FACILITY', 'blue');
    statusColorMapping.set('DEPARTED_CARRIER_FACILITY', 'blue');
    statusColorMapping.set('DELIVERED', 'green');
    statusColorMapping.set('RETURNED_TO_SENDER', 'red');
    statusColorMapping.set('OUT_FOR_DELIVERY', 'green');


    if (statusColorMapping.has(status)) {
        return statusColorMapping.get(status);
    } else {
        return 'blue';
    }


}

export const problematicShipmentStatuses = [
    'IN_TRANSIT_DELAYED',
    'RETURNED_TO_SENDER',
    'SHIPMENT_EXCEPTION',
]
import React from "react";
import {Upload, Button, Icon, message} from 'antd';
import {bold, boldLink} from "../../Utils/HtmlUtils";
import {getApiEnv} from "../../Utils/ApiEnv";

const {Dragger} = Upload;


const RateFileToolContainer = () => {
    const [fileList, setFileList] = React.useState([] as any)
    const [loading, setLoading] = React.useState(false)


    const handleUpload = () => {
        setLoading(true);


        fileList.forEach((file: any) => {
            const formData = new FormData();
            formData.append('files[]', file);

            let fileNanme = file.name;
            let fileNameWithoutExtension = fileNanme.split('.').slice(0, -1).join('.');


            fetch(`${getApiEnv().httpEndpoint}/post-file-flattener`, {
                method: 'POST',

                // File is sent as form data
                // File is received as a blob
                // content length is specified
                headers: {
                    'Content-Length': `${file.size}`,
                },

                body: formData
            })
                .then(response => response.json())
                .then(result => {
                    let resultData = result.body

                    const url = window.URL.createObjectURL(new Blob([resultData]));
                    const link = document.createElement('a');

                    link.href = url;
                    link.setAttribute('download', `${fileNameWithoutExtension}_Flattened.csv`);
                    document.body.appendChild(link);

                    link.click();
                    link.parentNode?.removeChild(link);

                })
                .catch(error => {
                    console.error('Error:', error);
                })
                .finally(() => {
                    setLoading(false);

                })
            ;


        });

    }


    return (
        <div
            style={{
                marginTop: "2%",
                width: "50%",
                marginLeft: "25%",
                height: "3000px",
                textAlign: "center",
                fontSize: "18px"
            }}
            id={"rate-file-tool-container"}
        >
            {/*Add list item stylesheet*/}
            <style>
                {`
                        
                      #rate-file-tool-container  ul li {
                            margin-bottom: 15px;
                        }
                    `}
            </style>

            <h1>Shipping Rate File Flattening Tool</h1>

            <div
                style={{
                    marginBottom: "5%",
                }}
            >
                <iframe
                    width="90%"
                    height={"350px"}
                    allow={"autoplay"}
                    src="https://www.youtube.com/embed/yUloUG86OBA?autoplay=1&mute=1"
                    title="File Flattening Tool Tutorial"
                ></iframe>

            </div>

            <div
                style={{
                    textAlign: "left"
                }}
            >
                <h2>About</h2>

                <ul>

                    <li>
                        Sometimes it seems like our beloved carriers are trying to make it as hard as possible for us to
                        study their rates.
                    </li>

                    <li>
                        Check out the
                        the {boldLink('USPS Public Rates page', 'https://pe.usps.com/text/dmm300/Notice123.htm#_c450:~:text=%5E%20Top-,Commercial%E2%80%94Letters%2C%20Large%20Envelopes%20%26%20Parcels,-Flat%20Rate%20Envelopes')}
                    </li>

                    <li>
                        It is much easier to get a sense of the rates if they are in
                        a {bold('Flat Vector')} format rather than the {bold('Weight x Zone Thick Matrix')} format
                    </li>

                    <li>
                        This tool helps shippers convert their rate files into a format that is easier to analyze and
                        empowers them to make better shipping decisions.
                    </li>

                    <li>
                        Specifically, this it converts rate files in {bold('Weight x Zone')} format which is easier to
                        analyze in {bold('Excel and SQL')}.
                    </li>

                    <li>
                        Normally what would take around {bold('10 minutes')} to do manually in Excel, this tool can do
                        in a few
                        seconds.
                    </li>

                    <li>
                        Works for {bold('UPS')}, {bold('USPS')} and {bold('FedEx')} rate files
                        in {bold('Weight vs Zone')} format.
                    </li>
                </ul>
            </div>

            <div>

                <img
                    src={'https://i.imgur.com/HQsLxKM.png'}
                    alt={'Rate File Flattening Tool'}
                    style={{
                        width: "90%",
                    }}
                />


            </div>


            <div>


                <div
                    style={{
                        marginTop: "5%",
                        marginBottom: "5%"
                    }}
                >
                    <div
                        style={{
                            textAlign: "left"
                        }}
                    >
                        <h2>File Format</h2>

                        <ul>

                            <li>
                                File needs to be in {bold('.xlsx')} format
                            </li>

                            <li>
                                Example files: <a
                                href={'https://docs.google.com/spreadsheets/d/15-0BTOEiIPgj5X53Ev3FQpre6ksP5a0ozewII0hWkr0/edit?gid=0#gid=0'}
                                target={'_blank'}
                                rel={'noopener noreferrer'}
                            >
                                {bold('USPS')}
                            </a>, <a
                                href={'https://docs.google.com/spreadsheets/d/1wL8JOYVgmgvYgZsOBqkK3qj1OF1aMsFl/edit?gid=1494486267#gid=1494486267'}
                                target={'_blank'}
                                rel={'noopener noreferrer'}
                            > {bold('FedEx')}

                            </a>, <a
                                href={'https://docs.google.com/spreadsheets/d/1TEJqGPGXuZZ498uanPMqOrdUS8xPUeKPajnp7B2JFrk/edit?gid=0#gid=0'}
                                target={'_blank'}
                                rel={'noopener noreferrer'}
                            >
                                {bold('UPS')}
                            </a>
                            </li>

                            <li>
                                The data needs to be the 1st sheet.
                            </li>

                            <li>
                                {bold('Rows #1 and #2')} are headers and will be skipped by the tool
                            </li>

                            <li>
                                Names of columns do not matter.
                            </li>

                            <li>
                                Column 1 is assumed to be {bold('Weight')}
                            </li>

                            <li>
                                Columns 2, 3, ..., N are assumed to be {bold('Zones')} 1, 2, ... N - 1
                            </li>

                            <li>
                                Number of zone columns can be any number between 1 and 9
                            </li>

                            <li>
                                The tool is optimized for raw {bold('.xlsx files for USPS')}
                            </li>

                            <li>
                                Raw {bold('.xlsx')} file for {bold('FedEx')} and {bold('UPS')} rates need to be slightly
                                modified before upload
                            </li>


                        </ul>
                    </div>
                </div>

                <div
                    style={{textAlign: "left"}}
                >
                    <h2>Tool</h2>
                </div>

                <div
                    style={{
                        marginTop: "5%",
                        width: "100%",
                        marginLeft: "0%",
                    }}
                >
                    <Dragger
                        multiple={false}
                        onRemove={(file: any) => {
                            const index = fileList.indexOf(file);
                            const newFileList = fileList.slice();
                            newFileList.splice(index, 1);
                        }}

                        beforeUpload={(file: any) => {
                            // Check if file is .xlsx
                            const fileType = file.type;

                            if (fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                                message.error('You can only upload .xlsx files!');
                                return true;
                            } else {
                                setFileList([...fileList, file]);
                                return false;
                            }


                        }}

                    >
                        <p className="ant-upload-drag-icon">
                            <Icon type="file"/>
                        </p>
                        <p className="ant-upload-text">Click or drag a rate file in the {bold('.xlsx')} format to
                            upload</p>

                        <p className="ant-upload-hint" style={{marginTop: '2%'}}>
                            More details on file format with examples below
                        </p>
                    </Dragger>
                </div>

                <div
                    style={{
                        marginTop: "5%",
                        marginBottom: "5%"
                    }}
                >
                    <Button
                        type="primary"
                        onClick={handleUpload}
                        disabled={fileList.length === 0}
                        loading={loading}
                    >
                        Get Flattened Rate File
                    </Button>
                </div>

            </div>

            <div
                style={{
                    marginTop: "15%",
                    marginBottom: "5%"
                }}
            >
                <div
                    style={{
                        textAlign: "left"
                    }}
                >
                    <h2>Where to go from here?</h2>

                    <ul>

                        <li>
                            Ok, so you got your rate file flattened but need extra help analyzing it?
                        </li>

                        <li>
                            Check out this {boldLink('LinkedIn Post', 'https://www.linkedin.com/feed/update/urn:li:activity:7216974589929631744/')} about how to use the flat
                            file to play carriers against each other and get a {bold('+10% discount')}.
                        </li>

                        <li>
                            Or, have a look at
                            the {boldLink('B2B Distributor Case Study', '/b2b-distributor-case-study')} if you are
                            curious how we help customers similar to you save {bold('+$10k every month')}.
                        </li>

                        <li>
                            Finally, need more tailored help? Schedule a complimentary {boldLink('Consultation', '/https://calendly.com/tom-3muv/perseuss-demo')}
                        </li>

                        <li>
                            You can also always subscribe to our {boldLink('Newsletter', '/home')} to get the latest on how AI impacts the supply chain industry.
                        </li>


                    </ul>
                </div>
            </div>

        </div>
    )

}

export default RateFileToolContainer;

import {Button, Collapse, Icon, Progress} from "antd";
import React from "react";
import {columnTitle, roundToFixed, roundToFixed2} from "../../API/Utils";
import {bold, boldBlue, boldRed, getCategoryTag, getDescriptionTag} from "../../Utils/HtmlUtils";
import DeposcoShipButton from "../ActionButtons/DeposcoShipButton";
import {ItemManager} from "../../Packing/ItemManager";
import ScanAllItemsButton from "../ActionButtons/ScanAllItemsButton";
import ResetScannedItemsButton from "../ActionButtons/ResetScannedItemsButton";

const {Panel} = Collapse;


export const getShipmentTableColumns = (
    itemManager: ItemManager,
    packingStationId: string
) => {

    return [
        {
            title: columnTitle('Box Details', ''),
            dataIndex: 'shipment_id',
            key: 'shipment_details',
            width: '15%',
            render: (shipmentId: any, record: any) => {
                let locationNumber = itemManager.getAuthService().getSlotNumberForShipmentId(+shipmentId)


                return <div
                    style={{
                        textAlign: 'center'
                    }}
                >

                    <h1>{boldRed(locationNumber)}</h1>
                    <h2>{boldBlue('Ship Method: ')} {record.ship_method}</h2>
                    {
                        record.ship_method === 'USPS Large Flat Rate Box' ?
                            <h2>{boldBlue('Box: ')} Large Flat Rate </h2> : null
                    }

                    {
                        record.ship_method === 'USPS Medium Flat Rate Box' ?
                            <h2>{boldBlue('Box: ')} Medium Flat Rate </h2> : null
                    }

                    {
                        record.ship_method === 'USPS Small Flat Rate Box' ?
                            <h2>{boldBlue('Box: ')} Small Flat Rate </h2> : null
                    }

                    {
                        record.ship_method === 'USPS Priority Mail' ?
                            <h2>{boldBlue('Box: ')} {record.box_name} </h2> : null
                    }


                    {
                        record.ship_method === 'USPS Priority Mail Cubic' ?
                            <h2>{boldBlue('Box: ')} {record.box_name} </h2> : null
                    }


                </div>
            }

        },
        {
            title: columnTitle('Shipment Details', ''),
            dataIndex: 'shipment_id',
            key: 'packing_progress',
            width: '15%',
            render: (text: any, record: any) => {

                return (
                    <div>
                        <h3>{bold('Cargo Weight: ')} {roundToFixed2(record.cargo_weight)} lbs</h3>
                        <h3>{bold('Box Weight: ')} {roundToFixed2(record.box_weight)} lbs</h3>
                        <h3>{bold('Total Weight: ')} {roundToFixed2(record.cargo_weight + record.box_weight)} lbs</h3>
                        <h3>{bold('Cargo Volume: ')} {roundToFixed2(record.cargo_volume)} cbft</h3>
                        <h3>{bold('Box Volume: ')} {roundToFixed2(record.box_volume)} cbft</h3>
                        <h3>{bold('Box Fill Rate: ')} {roundToFixed2(100 * record.cargo_volume / record.box_volume)} %</h3>

                    </div>
                )
            }
        },
        {
            title: columnTitle('Packing Progress', ''),
            dataIndex: 'item_quantities',
            key: 'lines_to_scan',
            width: '50%',
            render: (items: any, record: any) => {
                let itemQuantitiesToScan = record.item_quantities;

                // sort alphabetically by itemType in ascending order
                itemQuantitiesToScan.sort((a: any, b: any) => {
                    let itemTypeA = a[0];
                    let itemTypeB = b[0];

                    if (itemTypeA < itemTypeB) {
                        return -1;
                    }
                    if (itemTypeA > itemTypeB) {
                        return 1;
                    }
                    return 0;
                });


                let virtualBox = itemManager.getVirtualBox(record.shipment_id);


                let numItemsToScanTotal = virtualBox.getNumberOfUnscannedItems()
                let numItemsScannedTotal = virtualBox.getNumberOfScannedItems()


                let weightOfOriginalItems = virtualBox.getWeightOfOriginalItems();
                let weightOfScannedItems = virtualBox.getWeightOfScannedItems();

                let volumeOfOriginalItems = virtualBox.getVolumeOfOriginalItems();
                let volumeOfScannedItems = virtualBox.getVolumeOfScannedItems();

                let volumePercent = 100 * (volumeOfScannedItems / volumeOfOriginalItems);
                let weightPercent = 100 * (weightOfScannedItems / weightOfOriginalItems);


                let weightStatus: "active" | "success" | "exception" | "normal" | undefined;
                if (weightOfScannedItems === weightOfOriginalItems) {
                    weightStatus = 'success'
                } else if (weightOfScannedItems > weightOfOriginalItems) {
                    weightStatus = 'exception'
                } else {
                    weightStatus = 'active'
                }

                let volumeStatus: "active" | "success" | "exception" | "normal" | undefined;
                if (volumeOfScannedItems === volumeOfOriginalItems) {
                    volumeStatus = 'success'
                } else if (volumeOfScannedItems > volumeOfOriginalItems) {
                    volumeStatus = 'exception'
                } else {
                    volumeStatus = 'active'
                }


                return (

                    <div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '250px',
                            }}

                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    width: '70%',
                                    marginLeft: '15%'
                                }}

                            >
                                <div
                                    style={{
                                        marginTop: "2%",
                                        marginBottom: "2%",
                                        textAlign: 'center'

                                    }}
                                >
                                    <h3>Weight (lbs)</h3>
                                    <h3>{weightOfScannedItems} / {weightOfOriginalItems}</h3>
                                    <Progress
                                        percent={+roundToFixed(weightPercent, 0)}
                                        status={weightStatus}
                                        type={'dashboard'}
                                        width={90}
                                        format={p => `${roundToFixed2(weightPercent)}%`}
                                        strokeColor={{
                                            '100%': '#108ee9',
                                            '0%': '#87d068',
                                        }}
                                    />
                                </div>

                                <div
                                    style={{
                                        marginTop: "2%",
                                        marginBottom: "2%",
                                        textAlign: 'center'

                                    }}
                                >
                                    <h3>Volume (cube_ft)</h3>
                                    <h3>{volumeOfScannedItems} / {volumeOfOriginalItems}</h3>
                                    <Progress
                                        percent={+roundToFixed(volumePercent, 0)}
                                        status={volumeStatus}
                                        type={'dashboard'}
                                        width={90}
                                        format={p => `${roundToFixed2(volumePercent)}%`}
                                        strokeColor={{
                                            '100%': '#108ee9',
                                            '0%': '#87d068',
                                        }}
                                    />
                                </div>

                            </div>


                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    width: '80%',
                                    marginLeft: '10%'
                                }}

                            >
                                <ResetScannedItemsButton
                                    shipmentId={record.shipment_id}
                                    itemManager={itemManager}
                                    packingStationId={packingStationId}
                                />

                                <ScanAllItemsButton
                                    shipmentId={record.shipment_id}
                                    itemManager={itemManager}
                                    packingStationId={packingStationId}
                                />
                            </div>
                        </div>

                        <div>
                            <Collapse>
                                <Panel
                                    header={`Items Scanned: ${numItemsScannedTotal}/${numItemsToScanTotal + numItemsScannedTotal}`}
                                    key="1" style={{}}>

                                    <table>
                                        <thead>

                                        <tr
                                            style={{
                                                fontSize: '15px',
                                                textAlign: 'center'
                                            }}
                                        >
                                            <th>SKU</th>
                                            <th>Pic</th>
                                            <th>Item</th>
                                            <th>To-do</th>
                                            <th>Done</th>
                                        </tr>

                                        </thead>


                                        <tbody>

                                        {itemQuantitiesToScan.map((item: any, index: number) => {

                                            let itemType = item[0];
                                            let itemBody = itemManager.getRandomItemOfType(itemType)

                                            let virtualBox = itemManager.getVirtualBox(record.shipment_id);
                                            let scannedItemCount = virtualBox.getNumberOfScannedItemsForType(itemType);
                                            let itemsToScanCount = virtualBox.getNumberOfUnscannedItemsForType(itemType);


                                            return (
                                                <tr key={index} style={{
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                    backgroundColor: index % 2 === 0 ? '#d4ebf2' : 'white'
                                                }}>


                                                    <td style={{border: '1px solid black'}}>
                                                        <p>
                                                            {itemType}
                                                        </p>
                                                        <p>
                                                            {bold('UPC:')} {itemManager?.getUpcForItemType(itemType)}
                                                        </p>
                                                        <p>
                                                            {
                                                                itemBody?.getCategoryAsList()
                                                                .map((category: string, index: number) => getCategoryTag(category, 0, index))
                                                            }
                                                        </p>

                                                    </td>

                                                    <td style={{border: '1px solid black'}}>
                                                        <img
                                                            src={itemBody?.getImageThumbnail()}
                                                            alt={''}
                                                            style={{
                                                                width: '75px',
                                                                height: '75px',
                                                                borderRadius: '50%',
                                                                border: '2px solid #1890ff',
                                                            }}
                                                        />
                                                    </td>

                                                    <td style={{border: '1px solid black'}}>
                                                        <div>
                                                            <p>{bold(itemBody?.getBrandName())}: {itemBody?.getProductName()}</p>
                                                            <p>{itemBody?.getVariantName()}</p>
                                                            <p>
                                                                {
                                                                    itemBody?.getDescriptionTags()
                                                                        .map((tag: string, index: number) => getDescriptionTag(tag, 0 , index))
                                                                }
                                                            </p>
                                                        </div>
                                                    </td>

                                                    <td style={{border: '1px solid black', fontSize: '15px'}}>
                                                        <div>
                                                            {bold(itemsToScanCount)}
                                                        </div>

                                                        <div>
                                                            <Button

                                                                type={'danger'}

                                                                onClick={() => {
                                                                    itemManager.resetScanForItemInShipment(
                                                                        packingStationId,
                                                                        record.shipment_id,
                                                                        itemType
                                                                    )
                                                                }}

                                                                disabled={scannedItemCount === 0}

                                                            >
                                                                <Icon type={'undo'}></Icon>
                                                            </Button>
                                                        </div>
                                                    </td>

                                                    <td style={{border: '1px solid black', fontSize: '15px'}}>
                                                        <div>
                                                            {bold(scannedItemCount)}
                                                        </div>

                                                        <div>
                                                            <Button

                                                                type={'primary'}

                                                                onClick={() => {
                                                                    itemManager.scanItemForItemType(
                                                                        packingStationId,
                                                                        itemType
                                                                    )
                                                                }}

                                                                disabled={itemsToScanCount === 0}

                                                            >
                                                                <Icon type={'plus-circle'}></Icon>
                                                            </Button>
                                                        </div>
                                                    </td>

                                                </tr>
                                            )
                                        })}

                                        </tbody>

                                    </table>
                                </Panel>
                            </Collapse>
                        </div>

                    </div>
                )
            }

        },

        {
            title: columnTitle('Check Out', ''),
            dataIndex: 'item_quantities',
            key: 'action',
            render: (items: any, record: any) => {

                const numOfCostBreakdownItems = record.cost_breakdown.length;
                const totalCost = record.cost_breakdown[numOfCostBreakdownItems - 1][1];

                return (
                    <div>

                        <DeposcoShipButton
                            shipmentId={record.shipment_id}
                            itemManager={itemManager}
                            packingStationId={packingStationId}
                        />

                        <div
                            style={{
                                position: 'relative',
                                bottom: '15px',
                            }}
                        >
                            <Collapse>
                                <Panel header={`Shipping Cost: ${totalCost}`} key="1" style={{}}>
                                    <div>
                                        {record.cost_breakdown.map((item: any, index: number) => {
                                            return (
                                                <div
                                                    key={index}
                                                    style={{
                                                        marginTop: "2%",
                                                        marginBottom: "2%",

                                                    }}
                                                >
                                                    <div><span
                                                        style={{fontWeight: 'bold'}}> {item[0]}</span>
                                                    </div>
                                                    <div>= {item[1]}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Panel>
                            </Collapse>


                        </div>

                    </div>
                )
            }

        }

    ];
}

import {Button, Icon, Modal} from "antd";
import * as React from "react";
import {bold, boldRed} from "../../Utils/HtmlUtils";
import {ActionItemProps} from "./ActionButtonProps";


const ResetScannedItemsButton: React.SFC<ActionItemProps> = (props) => {
    return <div
        style={{
            width: '35%',
            marginBottom: '7.5%'
        }}
    >

        <Button
            type={'default'}
            style={{
                width: '100%'
            }}
            onClick={() => props.itemManager.updateFieldForShipmentId(
                props.shipmentId,
                'resetScanModalOpen',
                true
            )}
            disabled={props.itemManager.getNumberOfItemsScanned(props.shipmentId) === 0}
        >
            Reset Scan <Icon type={'undo'}></Icon>
        </Button>

        <Modal
            title="Reset Scanned Items"
            visible={props.itemManager.getFieldForShipmentId(props.shipmentId, 'resetScanModalOpen')}
            onOk={() => {
                props.itemManager.updateFieldForShipmentId(
                    props.shipmentId,
                    'resetScanModalOpen',
                    false,
                    false
                )
                props.itemManager.resetScanForAllItems(
                    props.packingStationId,
                    props.shipmentId
                )

            }}
            onCancel={() => {
                props.itemManager.updateFieldForShipmentId(
                    props.shipmentId,
                    'resetScanModalOpen',
                    false,
                    true
                )
            }}
        >
            <h1>{boldRed('DANGER')}</h1>
            <h2>Are you sure you want to {bold('RESET ALL SCANNED ITEMS')} for this shipment?</h2>


        </Modal>

    </div>
}

export default ResetScannedItemsButton

import React from "react";


const HomeContainer = () => {

    return (
        <div>
            <iframe
                src="https://perseuss.ck.page/f59ba43da7"
                width="100%"
                height="4000px"
                title={"Home"}
                style={{
                    border: 'none',
                    position: 'absolute',
                }}
            />

        </div>
    )

}

export default HomeContainer;

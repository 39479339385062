import React from "react";
import {withRouter} from "react-router";
import {useParams} from "react-router-dom";
import OptimizeToteDynamicContainer from "../OptimizeToteDynamicContainer";


interface ReplayRequestDynamicContainerProps {
    parentRequestId: string
}

const ReplayRequestDynamicContainer = () => {
    const {parentRequestId} = useParams<ReplayRequestDynamicContainerProps>()

    return (
        <div>
            <OptimizeToteDynamicContainer
                parentRequestId={parentRequestId}
            ></OptimizeToteDynamicContainer>
        </div>
    )

}

export default withRouter(ReplayRequestDynamicContainer);

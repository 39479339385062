import * as React from "react";
import {Menu, Icon} from 'antd';
import {useHistory, useLocation} from "react-router-dom";
import {useEffect} from "react";

type NavigationProps = {}

const Navigation: React.SFC<NavigationProps> = (props) => {
    const history = useHistory();
    const location = useLocation()

    const [selectedMenuItems, setSelectedMenuItems] = React.useState<Array<any>>([''])

    useEffect(() => {
        if (location.pathname) {
            let path = location.pathname.split('/')[1]
            if (!path) {
                path = 'shipment_tracking'
            }
            setSelectedMenuItems([path])
        }
    }, [location.pathname])

    const handleClick = (e: any) => {
        const selection = e.key
        setSelectedMenuItems([selection])

        history.push(`/${selection}`)
    }

    return <div
        style={{
            width: "90%",
            marginLeft: "5%",
        }}
    >
        <Menu onClick={handleClick} selectedKeys={selectedMenuItems} mode="horizontal">
            <Menu.Item key="home">
                <Icon type="home"/>
                Home
            </Menu.Item>

            <Menu.Item key="b2b-distributor-case-study">
                <Icon type="solution"/>
                Case Study
            </Menu.Item>

            <Menu.Item key="shipping-file-tool">
                <Icon type="tool"/>
                Shipping File Tool
            </Menu.Item>

            <Menu.Item key="login">
                <Icon type="user"/>
                Login
            </Menu.Item>

            <Menu.Item key="recsys">
                <Icon type="experiment"/>
                RecSys Test
            </Menu.Item>

            <Menu.Item key="reset-items">
                <Icon type="undo"/>
                Reset Items
            </Menu.Item>

            <Menu.Item key="optimize_tote">
                <Icon type="rocket"/>
                Box Optimiser
            </Menu.Item>
            <Menu.Item key="packing_stats">
                <Icon type="bar-chart"/>
                Packing Stats
            </Menu.Item>
            <Menu.Item key="request_history">
                <Icon type="history"/>
                Order History
            </Menu.Item>
            <Menu.Item key="view_simulation">
                <Icon type="experiment"/>
                USPS Analysis
            </Menu.Item>
            <Menu.Item key="shipment_tracking">
                <Icon type="global"/>
                Shipment Tracking
            </Menu.Item>
            <Menu.Item key="check_ship_via">
                <Icon type="eye"/>
                Check Ship Via
            </Menu.Item>
        </Menu>
    </div>
}

export default Navigation

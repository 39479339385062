import {Button, Icon, Modal} from "antd";
import * as React from "react";
import {bold, boldRed} from "../../Utils/HtmlUtils";
import {ActionItemProps} from "./ActionButtonProps";


const ScanAllItemsButton: React.SFC<ActionItemProps> = (props) => {
    return <div
        style={{
            width: '35%',
            marginBottom: '7.5%'
        }}
    >

        <Button
            type={'primary'}
            style={{
                width: '100%'
            }}
            onClick={() => props.itemManager.updateFieldForShipmentId(
                props.shipmentId,
                'scanAllModalOpen',
                true
            )}
            disabled={props.itemManager.getNumberOfItemsUnscanned(props.shipmentId) === 0}
        >
            Scan All <Icon type={'plus-circle'}></Icon>
        </Button>

        <Modal
            title="Scan All Items"
            visible={props.itemManager.getFieldForShipmentId(props.shipmentId, 'scanAllModalOpen')}
            onOk={() => {
                props.itemManager.updateFieldForShipmentId(
                    props.shipmentId,
                    'scanAllModalOpen',
                    false,
                    false
                )
                props.itemManager.scanAllItems(
                    props.packingStationId,
                    props.shipmentId
                );

            }}
            onCancel={() => {
                props.itemManager.updateFieldForShipmentId(
                    props.shipmentId,
                    'scanAllModalOpen',
                    false,
                    true
                )
            }}>
            <h1>{boldRed('DANGER')}</h1>
            <h2>Are you sure you want to {bold('SCAN ALL ITEMS')} for this shipment?</h2>


        </Modal>

    </div>
}

export default ScanAllItemsButton

import React, {useEffect, useState} from "react";
import {Button, Input, Spin} from "antd";
import {
    authSuccessful,
    backendCall,
} from "../../API/Utils";
import useAuthUser from "../../Hooks/auth/useAuthUser";
import {boldGreen, boldRed} from "../../Utils/HtmlUtils";
import {withRouter} from "react-router-dom";

const ShipViaCheckerContainer = () => {
    const [loading, setLoading] = useState(false);
    const [toteId, setToteId] = useState('');
    const [oldShipVia, setOldShipVia] = useState('');
    const {authUser, authPending} = useAuthUser();

    const allowedOldShipVias = [
        'USPS Flat Rate',
        'USPS Priority Mail'
    ]

    useEffect(() => {
        if (authSuccessful(authUser, authPending)) {
            console.log('Auth successful');
        }
    }, [authUser, authPending])


    const handleClick = () => {
        setLoading(true);
        backendCall('/get-ship-via-for-tote-id', {
            tote_id: toteId,
        }, r => r).then((response) => {
                if (response.result) {
                    setOldShipVia(response.result.ship_via);
                } else {
                    setOldShipVia('Error... Maybe wrong tote id?');

                }
                setLoading(false);
            }
        )
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginTop: '5%',
                marginLeft: '25%',
                width: '50%',
                height: '150px',
                textAlign: 'center'
            }}
        >
            <div>
                <h3>Check Ship Via</h3>
            </div>
            <div>
                <Input
                    value={toteId}
                    onChange={(e) => {
                        setToteId(e.target.value)
                    }}
                >
                </Input>
            </div>
            <div>
                <Button
                    onClick={handleClick}
                    type={'primary'}
                >
                    Check
                </Button>
            </div>
            <div>
                {loading ? <div><Spin/></div> : <div>
                    {oldShipVia ? <div>
                        <div>Old Ship Via: {allowedOldShipVias.includes(oldShipVia) ?
                            boldGreen(oldShipVia) : boldRed(oldShipVia)}</div>
                    </div> : <div></div>}
                </div>}

            </div>
        </div>

    )

}

export default withRouter(ShipViaCheckerContainer);

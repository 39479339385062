import {ItemManager} from "../ItemManager";
import {ItemOperationResult} from "./ItemOperationResult";
import {Item} from "../Item";
import {ItemOperationStatus} from "./ItemOperationStatus";
import {ActionLoggingService} from "../../Service/ActionLoggingService";

export abstract class ItemOperation {
    protected readonly itemManager: ItemManager;
    protected readonly packingStationId: string;
    protected readonly actionLoggingService: ActionLoggingService;

    protected constructor(itemManager: ItemManager, packingStationId: string) {
        this.itemManager = itemManager;
        this.packingStationId = packingStationId;
        this.actionLoggingService = itemManager.getActionLoggingService();
    }

    public abstract execute(resolve: (r: ItemOperationResult) => void): void;

    protected getPackingStationService() {
        return this.itemManager.getPackingStationService();
    }

    protected getItems() {
        return this.itemManager.getItems();
    }

    protected getVirtualBox(shipmentId: number) {
        return this.itemManager.getVirtualBox(shipmentId);
    }

    protected getItemForId(itemId: string) {
        return this.itemManager.getItemForId(itemId);
    }


    protected getUpcForItem(itemType: string) {
        return this.itemManager.getUpcForItemType(itemType);
    }

    protected validateScan(upc: string, resolve: (r: ItemOperationResult) => void): string | null {
        let itemOperationResult: ItemOperationResult;
        let itemType = this.itemManager.getItemTypeForUPC(upc);

        if (!itemType) {
            itemOperationResult = new ItemOperationResult(
                Item.emptyItem(),
                ItemOperationStatus.WRONG_UPC,
                0,
                0,
                0,
                '',
                [],
                'ERROR: INVALID UPC',
                [
                    `UPC: ${upc} `,
                    `PROBLEM: is not valid for any item in this order`,
                    `SOLUTION 1: You might need to open the box and scan the items individually`,
                    `SOLUTION 2: Is the item in the order? Maybe there was a picking error`,
                ]
            )

            if (upc === '') {
                itemOperationResult.shortErrorMessage = 'ERROR: EMPTY SCAN'
                itemOperationResult.longErrorMessages = [
                    `UPC: '${upc}' `,
                    `PROBLEM: Is Empty`,
                    `SOLUTION: Please click into the UPC Input Field`
                ]

            }

            this.getPackingStationService().scanItemFailure(
                upc,
                itemOperationResult.shortErrorMessage,
                r1 => {
                    this.actionLoggingService.logScanErrorAction(
                        upc,
                        itemOperationResult.shortErrorMessage,
                        r2 => resolve(itemOperationResult)
                    )
                })

            return null;

        } else {
            return itemType;

        }
    }


}
import {Spin, Table} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {Bar, XAxis, Tooltip as ChartTooltip, Legend, BarChart, ResponsiveContainer, YAxis} from 'recharts';
import {
    backendCall,
    columnTitle,
    formattedPackerName,
    roundToFixed2,
    shortDateStringToDateObjectWithoutShift,
    utcDateStringToLocalDateStringPlus1Day
} from "../../API/Utils";
import {
    bold,
    boldBlue,
    boldGreen,
    boldOrange,
    boldPurple,
    boldRed, getCategoryTag,
    getDescriptionTag,
} from "../../Utils/HtmlUtils";
import ShipmentView from "../../Components/Shared/ShipmentView";
import ItemsView from "../../Components/Shared/ItemsView";

interface InvoiceContainerProps {
    invoiceId: string
}

const invoicedOrderColumns = [
    {
        title: columnTitle('Order Details', ''),
        dataIndex: 'order_id',
        key: 'order_id',
        width: '19%',
        render: (orderId: any, record: any) => {
            return <div>

                <p>{bold('Savings: ')}
                    {record.savings > 0 ? boldGreen('$' + roundToFixed2(record.savings)) : boldRed('$' + roundToFixed2(record.savings))}
                    {record.savings > 0 ? boldGreen(' (' + roundToFixed2(record.savings_pctg) + '%)') : boldRed(' (' + roundToFixed2(record.savings_pctg) + '%)')}
                </p>

                <p>{bold('Tote: ')}{boldBlue(record.tote_id)} @ {boldOrange(record.order_id)}</p>
                <p>{bold('Destination:')} {record.destination_state} (Zone {record.rate_zone})</p>
                <p>{bold('Date:')} {shortDateStringToDateObjectWithoutShift(record.order_date).toDateString()}</p>

                <p>
                    {bold('Packed By:')} {formattedPackerName(record.packer_profile)}
                </p>

                <p>
                    {bold('Solution: ')}
                    <a
                        href={`/pack_solution/${record.solution_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {record.solution_id.substring(0, 8)}
                    </a>
                </p>

                <p>
                    {bold('Description Tags:')}
                </p>
                {
                    Object.keys(record.description_tags)
                        .map((tag: any, index: number) => getDescriptionTag(tag, record.description_tags[tag], index))
                }

                <p>
                    {bold('Categories:')}
                </p>
                {
                    Object.keys(record.categories)
                        .map((category: any, index: number) => getCategoryTag(category, record.categories[category], index))
                }


                {/*TODO: Maybe later when I have profile pictures of everyone I can add them here*/}
                {/*{record.packer_profile.photo_url.includes('slack-edge.com') ? <div*/}
                {/*    style={{*/}
                {/*        marginTop: "2%",*/}
                {/*        width: "30%",*/}
                {/*        marginLeft: "35%",*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <img*/}
                {/*        src={record.packer_profile.photo_url}*/}
                {/*        alt="Profile Pic"*/}
                {/*        style={{*/}
                {/*            width: "100%",*/}
                {/*            borderRadius: "50%",*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</div> : null}*/}

            </div>
        }
    },

    {
        title: columnTitle('Actual Shipments', ''),
        dataIndex: 'shipments_actual',
        key: 'shipments_actual',
        width: '27%',
        render: (shipmentsActual: any, record: any) => <ShipmentView
            shipmentData={shipmentsActual}
            positive={record.savings > 0}
            label={'Actual'}
            sort={true}
        />

    },

    {
        title: columnTitle('Baseline Shipments', ''),
        dataIndex: 'shipments_baseline',
        key: 'shipments_baseline',
        width: '27%',
        render: (shipmentsBaseline: any, record: any) => <ShipmentView
            shipmentData={shipmentsBaseline}
            positive={record.savings < 0}
            label={'Baseline'}
            sort={true}
        />

    },

    {
        title: columnTitle('Order Contents', ''),
        dataIndex: 'items',
        key: 'items',
        width: '27%',
        render: (items: any, record: any) => <ItemsView
            itemsData={items}
            orderValue={record.order_value_usd}
        />

    },


]


const InvoiceContainer = () => {

    const {invoiceId} = useParams<InvoiceContainerProps>();

    const [invoiceStatsLoading, setInvoiceStatsLoading] = useState<boolean>(true);
    const [invoicedOrdersLoading, setInvoicedOrdersLoading] = useState<boolean>(true);

    const [invoiceData, setInvoiceData] = useState<any>({} as any)
    const [orders, setOrders] = useState<any[]>([] as any[])

    const [pageSize, setPageSize] = useState<number>(30)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [totalOptimizedOrders, setTotalOptimizedOrders] = useState<number>(1000)

    const lastCurrentPage = useRef(-1);
    let lastPageSize = useRef(-1);

    const commonReportStyle = {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid black",
        marginBottom: "5px",
        color: "black"
    }

    useEffect(() => {
        if (invoiceId) {
            backendCall('/get-invoice-stats', {
                is_test: '0',
                invoice_id: invoiceId
            }, r => r).then((r: any) => {
                try {
                    setInvoiceData(r['result'])
                    setTotalOptimizedOrders(r['result']['num_optimized_orders_int'])
                } catch (e) {
                    console.log(e)
                    alert(e)
                } finally {
                    setInvoiceStatsLoading(false);
                }
            });
        }
    }, [invoiceId])


    useEffect(() => {
        if (invoiceId && pageSize && currentPage && totalOptimizedOrders && (lastCurrentPage.current !== currentPage || lastPageSize.current !== pageSize)) {
            lastCurrentPage.current = currentPage;
            lastPageSize.current = pageSize;

            backendCall('/get-invoiced-orders', {
                is_test: '0',
                invoice_id: invoiceId,
                page_size: pageSize,
                page_number: currentPage
            }, r => r).then((r: any) => {
                try {

                    let newOrders = r['result']['orders'].map((item: any, index: number) => {
                        return {
                            ...item,
                            key: `${item.order_id}_${item.tote_id}`
                        }
                    })

                    setOrders(newOrders)

                } catch (e) {
                    console.log(e)
                    alert(e)
                } finally {
                    setInvoicedOrdersLoading(false);
                }

            });
        }
    }, [invoiceId, pageSize, currentPage, totalOptimizedOrders])

    return (
        <div
            style={{
                width: "90%",
                marginLeft: "5%",
                marginTop: "10px",
                textAlign: "center"
            }}
        >
            <div
                style={{
                    marginTop: "2%",
                }}
            >
                <h1>{bold(invoiceId.toString().replace('_', ' ').replace('_', ' '))}  </h1>
            </div>

            {invoiceStatsLoading ? <Spin/> : <div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                    }}
                >
                    <div
                        style={{
                            width: "40%",
                        }}
                    >
                        <div
                            style={{
                                marginTop: "15px",
                                marginBottom: "15px"
                            }}
                        >
                            <h1>Savings Report</h1>
                        </div>

                        <div
                            style={{
                                paddingTop: "5px",
                                borderTop: "1px solid black",
                                ...commonReportStyle
                            }}

                        >
                            <h3>{bold('Report Period: ')}</h3>
                            <div>
                                <h4>
                                    {utcDateStringToLocalDateStringPlus1Day(invoiceData['invoice_start_date'])} - {utcDateStringToLocalDateStringPlus1Day(invoiceData['invoice_end_date'])}, {bold(invoiceData['num_days_in_invoice'])} working days
                                </h4>
                            </div>
                        </div>

                        <div
                            style={commonReportStyle}>
                            <h3>{bold('Orders:')}</h3>
                            <div>
                                {bold(invoiceData['num_optimized_orders'])} total orders, {bold(invoiceData['orders_per_day'])} orders per day,
                            </div>
                        </div>


                        <div
                            style={commonReportStyle}>
                            <h3>{bold('Total Shipped:')}</h3>
                            <div>
                                <h4>{bold(invoiceData['total_volume_shipped'])} cbft, {bold(invoiceData['total_weight_shipped'])} lbs, {bold(invoiceData['total_num_items_shipped'])} units </h4>
                            </div>
                        </div>


                        <div
                            style={commonReportStyle}>
                            <h3>{bold('Per Order:')}</h3>
                            <div>
                                <h4>{bold(invoiceData['volume_per_order'])} cbft, {bold(invoiceData['weight_per_order'])} lbs, {bold(invoiceData['num_items_per_order'])} units </h4>
                            </div>
                        </div>

                        <div
                            style={commonReportStyle}>
                            <h3>{bold('Baseline Cost:')} </h3>
                            <div>
                                <h4>{boldRed(invoiceData['shipping_cost_baseline'])} total, {boldRed(invoiceData['shipping_cost_baseline_per_order'])} per
                                    order</h4>
                            </div>
                        </div>

                        <div
                            style={{
                                ...commonReportStyle,
                                borderBottom: "3px solid black"
                            }}>
                            <h3>{bold('Actual Cost:')} </h3>
                            <div>
                                <h4>{boldBlue('-')}{boldBlue(invoiceData['shipping_cost_actual'])} total, {boldBlue('-')}{boldBlue(invoiceData['shipping_cost_actual_per_order'])} per
                                    order</h4>
                            </div>
                        </div>

                        <div
                            style={commonReportStyle}>
                            <h3>{bold('Gross Savings:')} </h3>
                            <div
                                style={{
                                    textAlign: "right"
                                }}
                            >
                                <h4>{boldPurple(invoiceData['total_savings'])} total
                                    , {boldPurple(invoiceData['savings_per_order'])} per order</h4>
                                <h4>gross
                                    saved {boldPurple(invoiceData['total_savings_pct'])} {' of baseline cost'} </h4>
                            </div>
                        </div>

                        <div
                            style={{
                                ...commonReportStyle,
                                borderBottom: "3px solid black"
                            }}
                        >
                            <h3>{bold('Perseuss Fee:')} </h3>

                            <div
                                style={{
                                    textAlign: "right"
                                }}
                            >
                                <h4>{boldOrange('-')}{boldOrange(invoiceData['perseuss_fee'])} total
                                    , {boldOrange('-')}{boldOrange(invoiceData['perseuss_fee_per_order'])} per
                                    order</h4>
                                <h4>{boldOrange('-')}{boldOrange(invoiceData['perseuss_fee_pctg'])} {' of total savings'}</h4>
                            </div>
                        </div>

                        <div
                            style={commonReportStyle}>
                            <h3>{bold('Net Savings:')} </h3>

                            <div
                                style={{
                                    textAlign: "right"
                                }}
                            >
                                <h4>{boldGreen(invoiceData['net_savings_total'])} total
                                    , {boldGreen(invoiceData['net_savings_per_order'])} per
                                    order</h4>
                                <h4>net saved {boldGreen(invoiceData['net_savings_pctg'])} {' of baseline cost'}</h4>
                            </div>
                        </div>

                    </div>

                    <div
                        style={{
                            width: "50%"
                        }}
                    >
                        <div
                            style={{
                                marginTop: "15px",
                                marginBottom: "15px"
                            }}
                        >
                            <h1>Savings Time Series</h1>
                        </div>
                        <div>
                            <ResponsiveContainer width="100%" height={220}>
                                <BarChart
                                    data={invoiceData.time_series.map((stat: any) => {

                                        return {
                                            date: shortDateStringToDateObjectWithoutShift(stat['order_date']).toDateString(),
                                            'Savings $': stat['savings']
                                        }
                                    })}
                                >
                                    <Legend/>
                                    <ChartTooltip/>
                                    <XAxis dataKey="date"/>
                                    <YAxis dataKey={'Savings $'}/>
                                    <Bar type="monotone" dataKey={'Savings $'} fill="green"/>
                                </BarChart>
                            </ResponsiveContainer>

                        </div>

                        <div>
                            <ResponsiveContainer width="100%" height={150}>
                                <BarChart
                                    data={invoiceData.time_series.map((stat: any) => {

                                        return {
                                            date: shortDateStringToDateObjectWithoutShift(stat['order_date']).toDateString(),
                                            'Number of Orders': stat['num_orders']
                                        }
                                    })}
                                >
                                    <Legend/>
                                    <ChartTooltip/>
                                    <XAxis dataKey="date"/>
                                    <YAxis dataKey={'Number of Orders'}/>
                                    <Bar type="monotone" dataKey={'Number of Orders'} fill="#1890ff"/>
                                </BarChart>
                            </ResponsiveContainer>

                        </div>
                    </div>
                </div>

                <div
                    style={{
                        marginTop: "5%"

                    }}
                >
                    <h1>Savings per Order Break Down</h1>
                    <Table
                        dataSource={orders}
                        columns={invoicedOrderColumns as any}
                        loading={invoicedOrdersLoading}

                        pagination={{
                            pageSize: pageSize,
                            position: 'both',
                            current: currentPage,
                            total: +totalOptimizedOrders,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                        }}
                        onChange={(pagination) => {
                            setInvoicedOrdersLoading(true);
                            setPageSize(pagination.pageSize ? pagination.pageSize : 50);
                            setCurrentPage(pagination.current ? pagination.current : 1);
                        }}
                    />
                </div>


            </div>}

        </div>

    )

}

export default InvoiceContainer;

import * as React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import {Form, Spin, Input, Button, notification, Col, Row, Icon} from 'antd';
import {colors} from '../../Themes/Colors';

type Props = RouteComponentProps & {
    form: any;
};

type State = {
    loading: boolean;
};

class LoginContainer extends React.Component<Props, State> {
    state = {
        loading: false
    };

    handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        this.props.form.validateFields((err: Error, values: { username: string; password: string }) => {
            if (!err) {
                let {username, password} = values;
                username = `${username}@perseuss.xyz`
                this.setState({loading: true});

                Auth.signIn(username, password)
                    .then(user => {
                        const {history} = this.props;

                        localStorage.setItem('ReactAmplify.TokenKey', user.signInUserSession.accessToken.jwtToken);

                        notification.success({
                            message: 'Succesfully logged in!',
                            description: 'Logged in successfully, Redirecting you in a few!',
                            placement: 'topRight',
                            duration: 1.5
                        });

                        history.push('/optimize_tote');

                    })
                    .catch(err => {
                        notification.error({
                            message: 'Error',
                            description: err.message,
                            placement: 'topRight'
                        });

                        console.log(err);

                        this.setState({loading: false});
                    });
            }
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {loading} = this.state;

        return (
            <div
                style={{
                    width: '50%',
                    marginLeft: '25%',
                    marginTop: '5%',
                    textAlign: 'center',
                }}
            >
                <React.Fragment>
                    <Form onSubmit={this.handleSubmit} className="login-form">

                        <div
                            style={{
                                margin: '1%',
                                textAlign: 'center'
                            }}
                        >
                        </div>
                        <Form.Item>
                            {getFieldDecorator('username', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input your username!'
                                    }
                                ]
                            })(
                                <Input prefix={<Icon type="user" style={{color: colors.transparentBlack}}/>}
                                       placeholder="Email"/>
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input your password!'
                                    }
                                ]
                            })(
                                <Input
                                    prefix={<Icon type="lock" style={{color: colors.transparentBlack}}/>}
                                    type="password"
                                    placeholder="Password"
                                />
                            )}
                        </Form.Item>
                        <Form.Item className="text-center">
                            <Row type="flex" gutter={16}>
                                <Col lg={24}>
                                    <Button
                                        style={{width: '100%'}}
                                        type="primary"
                                        disabled={loading}
                                        htmlType="submit"
                                        className="login-form-button"
                                    >
                                        {loading ? <Spin
                                            indicator={<Icon type="loading" style={{fontSize: 24}} spin/>}/> : 'Log in'}
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </React.Fragment>
            </div>);
    }
}

export default Form.create()(LoginContainer);

import {Input, Spin} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {bold} from "../../Utils/HtmlUtils";
import {ItemOperationProps} from "./ItemOperationProps";


const ScanItemInput: React.SFC<ItemOperationProps> = (props) => {
    const [oldNumScans, setOldNumScans] = useState(props.currentNumScans);
    const scanInput = useRef(null);

    useEffect(() => {
        if (props.isActive && props.currentNumScans > 0 && props.currentNumScans !== oldNumScans) {
            props.itemManager.scanItemForUpc(props.packingStationId, props.scannedItemUpc)

            // Little hack to make sure the focus goes back to the input field
            setTimeout(() => {
                if (scanInput.current) {
                    // @ts-ignore

                    scanInput.current.focus({preventScroll: true})

                }
            }, 100)

        }

        setOldNumScans(props.currentNumScans);

    }, [
        props.currentNumScans,
        oldNumScans,
        props.itemManager,
        props.packingStationId,
        props.scannedItemUpc,
        props.isActive
    ]);

    return (<div>
        {props.loading ? <div>
            <Spin/>
        </div> : <div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '90%',
                    marginLeft: '5%',
                    marginTop: '2%',
                    marginBottom: '2%'
                }}
            >
                <label>{bold('Item UPC:')}</label>
                <Input
                    placeholder="Please Scan Item UPC!"
                    ref={scanInput}
                    autoFocus={props.focusToScanInput}
                    value={props.scannedItemUpc}
                    onChange={(e) => {
                        props.setScannedItemUpc(e.target.value.toUpperCase())
                    }}
                    style={{
                        'width': '50%'
                    }}
                >
                </Input>


            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginTop: "2%",
                    marginBottom: "2%",
                    width: '90%',
                    marginLeft: '5%',
                }}
            >

            </div>
        </div>
        }

    </div>)
}

export default ScanItemInput

import {backendCall} from "../API/Utils";


export class UserService {
    private getUserDetailsEndpoint: string = '/get-user-details';
    private getAllProdPackersEndpoint: string = '/get-all-prod-users';
    private changeStationForUserEndpoint: string = '/change-station-for-user';

    private readonly packer: string;

    public constructor(packer: string) {
        this.packer = packer;
    }

    public getAllProdPackersAsync() {
        return backendCall(this.getAllProdPackersEndpoint, {
            with_stats: 1,
            user_id: this.packer
        }, r => r)
    }


    public async getUserDetailsAsync(): Promise<any> {
        return backendCall(this.getUserDetailsEndpoint, {
            packer_username: this.packer,
            user_id: this.packer
        }, r => r)
    }

    public changeStationForUser(
        newStationIndex: number,
        callback: (data: any) => void = (data: any) => {
        }
    ) {
        backendCall(this.changeStationForUserEndpoint, {
            packer_username: this.packer,
            new_station_index: newStationIndex,
            user_id: this.packer
        }, r => r).then((r: any) => {
            try {
                callback(r);
            } catch (e: any) {
                console.error('Something happened when calling the CHANGE_STATION_FOR_USER endpoint...')
                console.log(e)

                callback({
                    error: e
                })
            }
        })
    }


}

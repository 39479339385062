import {Button} from "antd";
import React from "react";
import {columnTitle, getRedAndGreenColorValues} from "../../API/Utils";
import ShipmentView from "../Shared/ShipmentView";
import {getAlgorithmTag} from "../../Utils/HtmlUtils";


export const SOLUTUION_TABLE_COLUMNS = [
    {
        title: columnTitle('Solution', ''),
        dataIndex: 'tote_data',
        key: 'number_of_shipments',
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
        sorter: (a: any, b: any) => a.tote_data.num_shipments - b.tote_data.num_shipments,
        render: (text: any, record: any) => {


            return <div>
                <ShipmentView
                    shipmentData={record.shipments}
                    positive={record.tote_data.savings > 0}
                    label={'Solution'}
                    sort={false}
                />
            </div>
        }

    },
    {
        title: columnTitle('Savings', ''),
        dataIndex: 'tote_data',
        key: 'savings',
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
        sorter: (a: any, b: any) => a.tote_data.savings - b.tote_data.savings,
        render: (text: any, record: any) => {
            let new_cost = record.tote_data.cost
            let baseline_cost = record.tote_data.baseline_cost
            let savings = baseline_cost - new_cost
            let savings_pctg = (savings / baseline_cost) * 100

            let [r_color, g_color] = getRedAndGreenColorValues(savings_pctg.toString())
            let sign = savings > 0 ? '+' : '-'
            let savings_abs = Math.abs(savings).toFixed(2)
            let savings_pctg_abs = Math.abs(savings_pctg).toFixed(2)


            return (<div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                textAlign: 'center',
                height: '100px'
            }}
            >
                <div>
                    <span
                        style={{
                            fontWeight: 'bold',
                            color: `rgb(${r_color}, ${g_color}, 0)`
                        }}>
                        {sign}${savings_abs} ({sign}{savings_pctg_abs}%)
                    </span>
                </div>

                <div>
                    {getAlgorithmTag(record.algorithm_name)}
                </div>
                <div>
                    <a href={`/pack_solution/${record['solution_id']}`} target={'_blank'}><Button type={'danger'}>
                        <p>PACK</p>
                    </Button></a>
                </div>
            </div>)
        }

    }

];

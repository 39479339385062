import {Button, Collapse, Tag, Icon} from "antd";
import React from "react";
import {
    columnTitle,
    dateStringToLocalString,
    utcDateStringToLocalDateString,
    utcStringToLocalString, utcToLocal
} from "../../API/Utils";
import MedusaOrderButton from "../../Components/ActionButtons/MedusaOrderButton";
import {getColorForShipmentStatus} from "./shipping-dashboard-config";
import {bold} from "../../Utils/HtmlUtils";

const {Panel} = Collapse;


export const SHIPPING_DASHBOARD_TABLE_COLUMNS = [
    {
        title: columnTitle('Shipment', ''),
        dataIndex: 'shipment_id',
        key: 'shipment_id',
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
        sorter: (a: any, b: any) => a.shipment_id - b.shipment_id,
        render: (text: any, record: any) => {
            return <div
                style={{
                    textAlign: 'center'
                }}
            >
                <div
                    style={{
                        marginBottom: '5px'
                    }}
                >
                    <div
                        style={{
                            marginBottom: '5%',
                        }}
                    >
                        <p>{bold('Order Number')}</p>
                        <p>{record.order_id}</p>
                        <MedusaOrderButton orderId={record.order_id}/>
                    </div>

                    <div>
                        <p>{bold('Shipment ID')}</p>
                        <p>{record.shipment_id}</p>
                        <a
                            href={`https://med.deposco.com/deposco/entity/detail;id=${record.shipment_id};bk=${record.shipment_id};entity=Shipment;tabIndex=0;currentLayoutId=undefined`}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <Button type={'primary'}><Icon type={'link'}/>Shipment Link</Button>
                        </a>
                    </div>
                </div>
            </div>
        }

    },
    {
        title: columnTitle('Tracking', ''),
        dataIndex: 'tracking_number',
        key: 'tracking_number',
        width: '10%',
        render: (text: any, record: any) => {
            return <div>
                <p><a href={record.tracking_url} target={'_blank'}>{record.tracking_number}</a></p>
                <p>@ {record.ship_via}</p>
            </div>
        }
    },
    {
        title: columnTitle('Recipient', ''),
        dataIndex: 'ship_to_attention',
        key: 'ship_to_attention',
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
        sorter: (a: any, b: any) => a.ship_to_attention - b.ship_to_attention,
        render: (text: any, record: any) => {
            return <div>
                <p>{record.ship_to_contact_name}</p>

                {/*Link to email with email icon*/}
                {record.ship_to_email ?
                    <p><a href={`mailto:${record.ship_to_email}`} target={'_blank'}><Icon type="mail"/> Email
                        Customer</a></p> : null}

                {/*Link to Phone*/}
                {record.ship_to_phone_number ?
                    <p><a href={`tel:${record.ship_to_phone_number}`} target={'_blank'}><Icon type="phone"/> Call
                        Customer</a></p> : null}

                <p>{record.ship_to_city}, {record.ship_to_state}</p>
            </div>
        }

    },
    {
        title: columnTitle('Status', ''),
        dataIndex: 'last_status',
        key: 'last_status',
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
        sorter: (a: any, b: any) => a.last_status - b.last_status,
        render: (text: any, record: any) => {
            return <div>
                <p><Tag
                    color={getColorForShipmentStatus(record.last_status)}>{bold(record.last_status)}</Tag></p>


                {/*Show the estimated delivery date and how many days we are late*/}
                {(record.estimated_delivery_date && (record.last_status !== 'DELIVERED')) ? <Tag color={record.delivery_delay_days > 0 ? 'red' : 'green'}>
                    <p>{bold('Ship Date (Ship Engine): ')}{utcDateStringToLocalDateString(record.ship_engine_ship_date)}</p>
                    <p>{bold('Today: ')}{new Date().toLocaleDateString()}</p>
                    <p>{bold('Promised Delivery Date: ')}{utcDateStringToLocalDateString(record.estimated_delivery_date)}</p>
                    <p>{bold('Delivery Days Delay: ')}{record.delivery_delay_days}</p>
                </Tag> : null}

                {/*TODO: Add how many days are remaining before we are late...*/}
                {/*Show the status description*/}

                <div
                    style={{
                        marginTop: '5%'

                    }}
                >
                    <p>{bold('Label Created On: ')} {dateStringToLocalString(record.deposco_ship_date)}</p>
                    <p>{bold('Last Event Time: ')} {utcStringToLocalString(record.events[record.events.length - 1].occurred_at)}</p>
                    <p>{bold('Status Description:')} {record.last_short_status_description}</p>
                    <p>{bold('More Details: ')}{record.last_long_status_description}</p>
                </div>
            </div>
        }

    },
    {
        title: columnTitle('Events', ''),
        dataIndex: 'events',
        key: 'events',
        width: '35%',
        render: (events: any, record: any) => {
            let num_items = events.length;

            return (
                <div>
                    <Collapse>
                        <Panel header={num_items} key="1" style={{}}>
                            <div>
                                <table
                                    width={'100%'}
                                >
                                    <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Description</th>
                                        <th>Location</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {events.map((event: any, index: number) => {
                                        let eventTime = utcToLocal(event.occurred_at)
                                        return (
                                            <tr key={index}>
                                                <td width={'33%'}>
                                                    <div>{eventTime.toDateString()}</div>
                                                    <div>{bold(eventTime.toLocaleTimeString())} (EST)</div>
                                                </td>
                                                <td width={'33%'}>{event.description}</td>
                                                <td width={'33%'}>{event.city_locality}, {event.state_province}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </Panel>
                    </Collapse>

                </div>
            )
        }

    }

];

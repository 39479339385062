import {Auth} from 'aws-amplify';
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {UserService} from "../../Service/UserService";
import {noAuthEmail} from "./auth-utils";

const useAuthUser = () => {
    const [authPending, setAuthPending] = useState(true);
    const [authUser, setAuthUser] = useState({
        email: '',
        packer_username: '',
        photo_url: '',
        full_name: '',
        packing_station_index: 0,
    });

    const history = useHistory();


    useEffect(() => {
        if (history) {
            Auth.currentAuthenticatedUser().then((userData) => {

                let email = userData.attributes.email;
                let userName = email.split('@')[0];
                new UserService(userName).getUserDetailsAsync().then((data) => {
                    let userData = data.result.user;

                    let user = {
                        email: email,
                        packer_username: userName,
                        photo_url: userData.photo_url,
                        full_name: userData.full_name,
                        packing_station_index: userData.packing_station_index,
                    }

                    setAuthUser(user);
                    setAuthPending(false);

                }).catch((err) => {
                    setAuthUser({
                        email: noAuthEmail,
                        packer_username: '',
                        photo_url: '',
                        full_name: '',
                        packing_station_index: 0
                    });

                    setAuthPending(false);
                    history.push('/login');
                });


            }, (err) => {
                setAuthUser({
                    email: noAuthEmail,
                    packer_username: '',
                    photo_url: '',
                    full_name: '',
                    packing_station_index: 0
                });

                setAuthPending(false);
                history.push('/login');
            })
        }

    }, [history]);

    return {authUser, authPending}
}


export default useAuthUser

import {ItemOperationStatus} from "./ItemOperationStatus";
import {Item} from "../Item";

export class ItemOperationResult{

    public static emptyResult(): ItemOperationResult{
        return new ItemOperationResult(
            {} as Item,
            ItemOperationStatus.EMPTY,
            0,
            0,
            0,
            '',
            [],
            '',
            []
        )
    }

    public item: Item;
    public status: ItemOperationStatus;
    public remainingQuantity: number;
    public originalQuantity: number;
    public scannedQuantity: number;
    public shortErrorMessage: string;
    public longErrorMessages: Array<string>;
    public shortInfoMessage: string;
    public longInfoMessages: Array<string>;


    constructor(
        item: Item,
        status: ItemOperationStatus,
        remainingQuantity: number,
        originalQuantity: number,
        scannedQuantity: number,
        shortInfoMessage: string,
        longInfoMessages: Array<string>,
        shortErrorMessage: string,
        longErrorMessages: Array<string>
    ){
        this.item = item;
        this.status = status;
        this.remainingQuantity = remainingQuantity;
        this.originalQuantity = originalQuantity;
        this.scannedQuantity = scannedQuantity;
        this.shortErrorMessage = shortErrorMessage;
        this.longErrorMessages = longErrorMessages;
        this.shortInfoMessage = shortInfoMessage;
        this.longInfoMessages = longInfoMessages;

    }

}
import {Button, Icon} from "antd";
import * as React from "react";
import {backendCall} from "../../API/Utils";
import {
    ShippingDashboardFilterProps
} from "../../Containers/ShippingDashboardHomepageContainer/FilterManager";

type ShipmentsDownloadButtonProps = {
    filterState: ShippingDashboardFilterProps,
}


const ShipmentsDownloadButton: React.SFC<ShipmentsDownloadButtonProps> = (props) => {
    const [loading, setLoading] = React.useState(false)

    const handleClick = () => {
        setLoading(true)
        backendCall('/get-shipping-dashboard-download-link', {
            is_test: '0',
            status_list: props.filterState.statusList.join(','),
            start_date: props.filterState.startDate,
            end_date: props.filterState.endDate

        }, r => r).then((r: any) => {
            setLoading(false);
            try{
                const url = r['result']['download_link']
                window.open(url, "_blank", "noreferrer");
            } catch (e) {
                console.log(e)
            }


        })
    }

    return <div>
        <Button
            loading={loading}
            disabled={loading}
            type={'primary'}
            onClick={handleClick}
        >
            Download Results <Icon type={'cloud-download'}/>
        </Button>
    </div>
}

export default ShipmentsDownloadButton

import {Button, Collapse, Icon, Input, Modal, Progress, Select, Spin} from "antd";
import * as React from "react";
import {bold, boldBlue, boldGreen, boldOrange, boldRed} from "../../Utils/HtmlUtils";
import {getApiEnv} from "../../Utils/ApiEnv";
import {
    DEFAULT_ALL_BOX_TYPES,
    DEFAULT_ALL_BOX_TYPES_CUBIC,
    roundToFixed,
    roundToFixed2,
    roundToFixed4
} from "../../API/Utils";
import {ActionItemProps} from "./ActionButtonProps";
import {Item} from "../../Packing/Item";
import {ActionLoggingService} from "../../Service/ActionLoggingService";

const {Panel} = Collapse;
const {Option} = Select;

export const ALLOWED_BOX_PREFIXES = [
    'BOX-',
    '1266-',
    '108-',
    '1812-',
    'MDX-',
    'BFB-',
    '129-',
    '126-',
    '40F-',
    '12C-',
    'MSTR-',
    'BB-',
    '18F-',
    'MFR-',
    'LFR-',
    'SFR-'
]

export const SHIP_METHOD_TO_BOX_MAPPING = {
    'USPS Priority Mail': DEFAULT_ALL_BOX_TYPES,
    'USPS Priority Mail Cubic': DEFAULT_ALL_BOX_TYPES_CUBIC,
    'USPS Large Flat Rate Box': ['Large Flat Rate'],
    'USPS Medium Flat Rate Box': ['Medium Flat Rate'],
    'USPS Small Flat Rate Box': ['Small Flat Rate']
} as any


const DeposcoShipButton: React.SFC<ActionItemProps> = (props) => {

    const [loading, setLoading] = React.useState(false)
    const [boxId, setBoxId] = React.useState('')
    const [deposcoShipmentId, setDeposcoShipmentId] = React.useState('')
    const [disappear, setDisappear] = React.useState(false)
    const [progressMessage, setProgressMessage] = React.useState('')

    const [currentAttempt, setCurrentAttempt] = React.useState(0);
    const [maxAttempts, setMaxAttempts] = React.useState(100);

    const [itemsMoved, setItemsMoved] = React.useState(0);
    const [totalItems, setTotalItems] = React.useState(100);

    const [modalOpen, setModalOpen] = React.useState(false);

    const virtualBox = props.itemManager.getVirtualBox(props.shipmentId);


    const buttonDisabled = () => {
        return loading || !boxId
    }

    const cleanBoxType = (boxType: string, shipMethod: string) => {
        if (shipMethod === 'USPS Priority Mail' || shipMethod === 'USPS Priority Mail Cubic') {
            return boxType
        } else {
            return SHIP_METHOD_TO_BOX_MAPPING[shipMethod][0]
        }

    }

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter' && !buttonDisabled()) {

            // Trying to make sure the ENTER event from this does not make it all the way to the SCAN ITEM component
            e.preventDefault();
            e.stopPropagation();

            validateShipment();
        }
    }

    const validateShipment = () => {
        // If there are 0 items in the shipment, alert the user and return
        if (virtualBox.getNumberOfScannedItems() === 0) {
            alert('No items in shipment')
            setLoading(false);
            return
        }

        // If the boxId does not have a Box Barcode prefix, alert the user and return
        let containsValidPrefix = false;
        ALLOWED_BOX_PREFIXES.forEach((prefix) => {
            if (boxId.startsWith(prefix)) {
                containsValidPrefix = true;
            }
        })
        if (!containsValidPrefix) {
            alert(`Box ID must start with one of the following prefixes: ["${ALLOWED_BOX_PREFIXES.join('" , "')}"]`)
            setLoading(false);
            return
        }

        // If the boxId is the same as some other boxId, alert the user and return
        if (props.itemManager.isBoxScanned(boxId)) {
            alert(`Box ID ${boxId} has already been scanned. Have you scanned the same box twice?`)
            setLoading(false);
            return
        }

        // If all validations succeed, we open the final review dialog
        setModalOpen(true);

    }

    const handleShipment = () => {
        setModalOpen(false);
        setLoading(true);
        let shipment = virtualBox.getShipmentBody();


        let wsAPIUrl = `${getApiEnv().websocketEndpoint}/handle-shipment`;
        console.log('Connection to Websocket at ', wsAPIUrl)
        const socket = new WebSocket(wsAPIUrl);


        socket.onopen = () => {
            props.itemManager.addScannedBox(boxId);
            socket.send(JSON.stringify({
                shipment_config: {
                    from_container: shipment.tote_id,
                    solution_id: shipment.solution_id,
                    to_container: boxId,
                    order_id: shipment.order_id,
                    ship_via: shipment.ship_method,
                    ship_vendor: 'USPS',
                    items: virtualBox.getShippingBox().getAggregatedItems(),
                    box_type: shipment.box_name,
                }
            }))

        }

        socket.addEventListener('message', ev => {
                try {
                    const eventData = JSON.parse(ev.data);
                    let connectionStatus = eventData.connection_status;

                    if (connectionStatus === 'success') {
                        if (eventData.shipment_mapped) {
                            setLoading(false);
                            setDisappear(true);
                            setProgressMessage('');
                            socket.close()


                            new ActionLoggingService(props.itemManager).logShipmentCheckout(
                                props.shipmentId,
                                eventData.deposco_shipment_id,
                                boxId
                            )

                            return

                        } else {
                            setDeposcoShipmentId(eventData.deposco_shipment_id)
                            setLoading(true);

                        }
                    } else if (connectionStatus === 'error') {
                        alert('Error: ' + eventData.error)
                        setDeposcoShipmentId('');
                        props.itemManager.removeScannedBox(boxId);
                        setBoxId('');
                        setLoading(false);
                        socket.close()

                    } else if (connectionStatus === 'update_in_progress') {
                        setCurrentAttempt(eventData.current_attempt);
                        setMaxAttempts(eventData.max_attempts);
                        setProgressMessage(eventData.message)

                    } else if (connectionStatus === 'items_moved') {
                        console.log('Count Data:')
                        console.log(eventData)
                        setItemsMoved(eventData.moved_item_total_count);
                        setTotalItems(eventData.total_item_count);

                    } else {
                        alert('Unknown Error ' + JSON.stringify(eventData))
                        setDeposcoShipmentId('');
                        props.itemManager.removeScannedBox(boxId);
                        setBoxId('');
                        setLoading(false);
                    }
                } catch
                    (e) {
                    setLoading(false);
                    setDeposcoShipmentId('');
                    setBoxId('');
                    console.log(e)
                    alert(e)
                }
            }
        )
    }

    return <div
        style={{
            textAlign: 'center'
        }}
        onKeyPress={handleKeyPress}
    >

        <div
            style={{
                marginBottom: '7.5%'

            }}

        >
            <Modal
                title="Check Out Shipment"
                visible={modalOpen}
                onOk={() => {
                    handleShipment();

                }}
                onCancel={() => {
                    setModalOpen(false);
                }}

                width={'50%'}
            >
                <div
                    style={{
                        textAlign: 'center',
                        width: '100%'

                    }}

                >
                    {
                        virtualBox.getNumberOfUnscannedItems() > 0 ? <div
                            style={{
                                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                padding: '1%',
                                marginBottom: '2%',
                                border: '3px solid red',
                                borderRadius: '20px'

                            }}

                        >
                            <h1>{boldRed('WARNING: ')} Some items are not scanned yet</h1>
                            <h2>{boldRed(`Unscanned Items (${virtualBox.getNumberOfUnscannedItems()}): `)}</h2>
                            {
                                virtualBox.getAggregatedItems().map((item, index) => {
                                    let itemBody: Item = props.itemManager.getRandomItemOfType(item[0]) as Item

                                    return <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            borderBottom: '1px solid black',
                                            marginBottom: '1%'
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: '60px',
                                                padding: '5px',
                                            }}
                                        >
                                            <h3>{bold(item[1])} x {itemBody?.getBrandName()}</h3>
                                            <p>{bold(itemBody?.getProductName())} {itemBody?.getVariantName()}</p>

                                        </div>
                                        <img
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                marginLeft: '1%',
                                                border: '1px solid red',
                                                borderRadius: '50%'
                                            }}
                                            src={itemBody?.getImageThumbnail()}
                                            alt={''}
                                        />


                                    </div>
                                })
                            }


                        </div> : null
                    }

                    <div
                        style={{
                            backgroundColor: 'rgba(0, 0, 255, 0.1)',
                            padding: '1%',
                            marginBottom: '2%',
                            border: '3px solid blue',
                            borderRadius: '20px'
                        }}
                    >

                        <h1>{boldBlue('Box & Shipment Review')}</h1>
                        <h2>{bold('Box Slot: ')} {props.itemManager.getAuthService().getSlotNumberForShipmentId(virtualBox.getShipmentId())}</h2>
                        <h2>{bold('Box ID: ')} {boxId}</h2>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <h2>{bold('Ship Method: ')} </h2>

                            <Select
                                style={{
                                    width: '30%',
                                    marginLeft: '1%'
                                }}
                                onChange={(selectedShipMethod: string) => {
                                    props.itemManager.overrideShipMethodForShipmentId(props.shipmentId, selectedShipMethod);
                                    props.itemManager.overrideBoxTypeForShipmentId(props.shipmentId, SHIP_METHOD_TO_BOX_MAPPING[selectedShipMethod][0]);
                                }}
                                value={virtualBox.getShipmentBody().ship_method}
                            >
                                {Object.keys(SHIP_METHOD_TO_BOX_MAPPING).map((shipMethod, index) => {
                                    return <Option key={index} value={shipMethod}>{shipMethod}</Option>
                                })}
                            </Select>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <h2>{bold('Box Type: ')} </h2>
                            <Select
                                style={{
                                    width: '30%',
                                    marginLeft: '1%'
                                }}
                                onChange={(selectedBoxName: string) => {
                                    props.itemManager.overrideBoxTypeForShipmentId(props.shipmentId, selectedBoxName);
                                }}
                                value={cleanBoxType(
                                    virtualBox.getShipmentBody().box_name,
                                    virtualBox.getShipmentBody().ship_method
                                )}
                            >

                                {SHIP_METHOD_TO_BOX_MAPPING[virtualBox.getShipmentBody().ship_method].map((boxType: string, index: number) => {
                                    return <Option key={index} value={boxType}>{boxType}</Option>
                                })}


                            </Select>
                        </div>
                    </div>

                    <div
                        style={{
                            backgroundColor: 'rgba(255, 165, 0, 0.1)',
                            padding: '1%',
                            marginBottom: '2%',
                            border: '3px solid orange',
                            borderRadius: '20px',
                            color: 'black'
                        }}

                    >
                        <h1>{boldOrange('Items Review')} </h1>
                        <h2>{bold('Number of Items: ')} {virtualBox.getNumberOfScannedItems()}</h2>
                        <h2>{bold('Box Weight: ')} {virtualBox.getShippingBox().getBoxWeight()} lbs</h2>
                        <h2>{bold('Weight of Items: ')} {virtualBox.getWeightOfScannedItems()} lbs</h2>
                        <h2>{bold('Total Shipment Weight: ')} {roundToFixed2(virtualBox.getShippingBox().getBoxWeight() + virtualBox.getWeightOfScannedItems())} lbs</h2>


                        <div
                            style={{
                                marginTop: '5%'
                            }}
                        >
                            <h2>{bold(`Item Breakdown (${virtualBox.getNumberOfScannedItems()}):`)}</h2>
                            <table
                                width={'100%'}
                            >
                                <thead>
                                <tr>
                                    <td style={{border: '1px solid black'}}>{bold('SKU')}</td>
                                    <td style={{border: '1px solid black'}}>{bold('Dims (W x H x L)')}</td>
                                    <td style={{border: '1px solid black'}}>{bold('Weight')}</td>
                                    <td style={{border: '1px solid black'}}>{bold('Volume')}</td>

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    // Sorting aggregated items by volume descending...
                                    // feels overcomplicated but that's what we re doing here... haha!
                                    virtualBox.getShippingBox().getAggregatedItems(
                                        (a: any, b: any) => {
                                            let vol_a = props.itemManager.getRandomItemOfType(a[0])?.getVolumeCubicFeet() as number
                                            let vol_b = props.itemManager.getRandomItemOfType(b[0])?.getVolumeCubicFeet() as number
                                            return vol_b - vol_a
                                        }
                                    ).map((itemTuple, index) => {
                                        let itemType = itemTuple[0]
                                        let itemQuantity = itemTuple[1]
                                        let item = props.itemManager.getRandomItemOfType(itemType)

                                        return <tr key={index}>

                                            {/*Item Row*/}
                                            <td style={{
                                                border: '1px solid black'
                                            }}>
                                                <div>

                                                    <h3>{bold(itemQuantity)} x {item?.getBrandName()}</h3>
                                                    <p>{bold(item?.getProductName())} {item?.getVariantName()}</p>

                                                </div>
                                                <img
                                                    style={{
                                                        width: '50px',
                                                        height: '50px',
                                                        marginLeft: '1%',
                                                        border: '1px solid orange',
                                                        borderRadius: '50%'
                                                    }}
                                                    src={item?.getImageThumbnail()}
                                                    alt={''}
                                                />


                                            </td>

                                            {/*Item Dimensions*/}
                                            <td style={{border: '1px solid black'}}>
                                                {bold(item?.getWidthInches())} in x {bold(item?.getHeightInches())} in
                                                x {bold(item?.getLengthInches())} in
                                            </td>

                                            {/*Item Weight*/}
                                            <td style={{border: '1px solid black'}}>
                                                <p>{bold(`${roundToFixed2((item ? item.getWeightLbs() * itemQuantity : 0) / virtualBox.getWeightOfScannedItems() * 100)}%`)} </p>
                                                <p>{bold(`${itemQuantity} items: `)} {item ? roundToFixed2(item.getWeightLbs() * itemQuantity) : 0} lbs</p>
                                                <p>{bold('1 item: ')} {roundToFixed2(item?.getWeightLbs() as number)} lbs</p>
                                            </td>

                                            {/*Item Volume*/}
                                            <td style={{border: '1px solid black'}}>
                                                <p>{bold(`${roundToFixed2((item ? item.getVolumeCubicFeet() * itemQuantity : 0) / virtualBox.getVolumeOfScannedItems() * 100)}%`)} </p>
                                                <p>{bold(`${itemQuantity} items: `)} {item ? roundToFixed4(item.getVolumeCubicFeet() * itemQuantity) : 0} cbft</p>
                                                <p>{bold('1 item: ')} {roundToFixed4(item?.getVolumeCubicFeet() as number)} cbft</p>
                                            </td>

                                        </tr>
                                    })
                                }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


            </Modal>
        </div>
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                height: '260px',
            }}
        >
            <div
                style={{}}
            >

                {
                    !deposcoShipmentId ?
                        <div>
                            <Input
                                style={{
                                    height: '50px',
                                }}
                                placeholder={'Enter Box ID'}
                                onChange={(e) => setBoxId(e.target.value.toUpperCase())}
                            />
                            {
                                (loading && (itemsMoved < totalItems)) ? <div>
                                    <p>Moving Items...</p>
                                    <Spin/>
                                    <Progress percent={+roundToFixed(100 * (itemsMoved / totalItems), 0)}
                                              status="active"/>

                                </div> : null
                            }
                        </div> :
                        <div>

                            <p>{bold('Deposco Shipment ID: ')} {deposcoShipmentId}</p>
                            <p>{bold('Box ID: ')} {boxId}</p>

                            {
                                (loading && (itemsMoved === totalItems) && !deposcoShipmentId) ? <div>
                                    <p>Creating a new Deposco Shipment...</p>
                                    <Spin/>
                                </div> : null
                            }

                            {
                                (loading && deposcoShipmentId && (itemsMoved === totalItems)) ? <div>
                                    <p>Waiting for Deposco...</p>
                                    <Spin/>
                                </div> : null
                            }
                            {progressMessage ? <div>
                                <Progress percent={+roundToFixed(100 * (currentAttempt / maxAttempts), 0)}
                                          status="active"/>
                                <Collapse>
                                    <Panel header={'Logs'} key="1">
                                        <p>{progressMessage}</p>
                                    </Panel>
                                </Collapse>

                            </div> : null}
                        </div>

                }
            </div>

            <div>
                {
                    disappear ? <div>
                        <p>{boldGreen('SUCCESS: ')} Call to Deposco Succeeded!</p>
                        <p>{boldRed('NEXT STEP: ')} Go to Deposco Handheld Menu and scan the box to print the label</p>

                    </div> : <div
                        style={{
                            width: '50%',
                            marginLeft: '25%',
                        }}
                    >
                        {/*Here we let the packer do a last minute override of the ship method*/}
                        <Button
                            style={{
                                width: '100%',
                            }}
                            loading={loading}
                            disabled={buttonDisabled()}
                            type={'danger'}
                            onClick={validateShipment}
                        >
                            Ship! <Icon type={'rocket'}></Icon>
                        </Button>
                    </div>
                }
            </div>
        </div>
    </div>
}

export default DeposcoShipButton

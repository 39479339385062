import * as React from 'react';
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom';
import {BrowserView, MobileView} from "react-device-detect";
import LoginContainer from "../Containers/LoginContainer";
import SimulationViewContainer from "../Containers/SimulationViewContainer";
import ToteSimulationDetailsContainer from "../Containers/ToteSimulationDetailsContainer";
import Navigation from "../Components/Shared/Navigation";
import RequestHistoryContainer from "../Containers/RequestHistoryContainer";
import ReplayRequestDynamicContainer from "../Containers/ReplayRequestDynamicContainer";
import OptimizeToteDynamicContainer from "../Containers/OptimizeToteDynamicContainer";
import PackSolutionContainer from "../Containers/PackSolutionContainer";
import ShippingDashboardHomepageContainer from "../Containers/ShippingDashboardHomepageContainer";
import ShipViaCheckerContainer from "../Containers/ShipViaCheckerContainer";
import PackingStatsContainer from "../Containers/PackingStatsContainer";
import HomeContainer from "../Containers/HomeContainer";
import InvoiceContainer from "../Containers/InvoiceContainer";
import BlogContainer from "../Containers/BlogContainer";
import RateFileToolContainer from "../Containers/RateFileToolContainer";
import RecSysContainer from "../Containers/RecSysContainer";
import ResetItemsContainer from "../Containers/ResetItemsContainer";

const AppRouter = () => {
    const queryParams = new URLSearchParams(window.location.search);
    let hideNavigation = false;

    if(queryParams.get('hideNavigation') && queryParams.get('hideNavigation') === '1'){
        hideNavigation = true
    }

    return (
        <div>
            <Router>

                {hideNavigation ? null : <Navigation/>}
                <Switch>

                    {/*Sitemap Routes Crawled by Google for SEO*/}
                    <Route exact={true} path="/home" component={HomeContainer}/>

                    <Route exact={true} path="/b2b-distributor-case-study">
                        <BrowserView>
                            <BlogContainer
                                blogWidthPercent={50}
                                contentWidthPercent={90}
                                videoHeightPx={350}
                                fontSizePx={18}
                            />
                        </BrowserView>

                        <MobileView>
                            <BlogContainer
                                blogWidthPercent={90}
                                contentWidthPercent={90}
                                videoHeightPx={200}
                                fontSizePx={16}
                            />
                        </MobileView>

                    </Route>


                    <Route exact={true} path="/shipping-file-tool" component={RateFileToolContainer}/>
                    <Route exact={true} path="/usps-shipping-calculator" component={RateFileToolContainer}/>

                    <Route exact={true} path="/recsys" component={RecSysContainer}/>

                    <Route exact={true} path="/reset-items" component={ResetItemsContainer}/>

                    {/*Accounts & Login Routes*/}
                    <Route exact={true} path="/login" component={LoginContainer}/>

                    {/*TODO: Disabling Sign Up for now because we do not want people randomly creating accounts*/}
                    {/*<Route exact={true} path="/signup" component={SignUpContainer}/>*/}


                    {/*App Specific Routes behind a Login*/}
                    <Route exact={true} path="/optimize_tote">
                        <OptimizeToteDynamicContainer
                            parentRequestId={''}
                        />
                    </Route>

                    <Route exact={true} path="/view_simulation">
                        <SimulationViewContainer/>
                    </Route>

                    <Route exact={true} path="/request_history">
                        <RequestHistoryContainer/>
                    </Route>

                    <Route exact={true} path="/order_id/:orderId/tote_id/:toteId">
                        <ToteSimulationDetailsContainer/>
                    </Route>


                    <Route exact={true} path="/pack_solution/:solutionId">
                        <PackSolutionContainer/>
                    </Route>


                    <Route exact={true} path="/invoice/:invoiceId">
                        <InvoiceContainer/>
                    </Route>

                    <Route exact={true} path="/replay_request/:parentRequestId">
                        <ReplayRequestDynamicContainer/>
                    </Route>

                    <Route exact={true} path="/shipment_tracking">
                        <ShippingDashboardHomepageContainer/>
                    </Route>

                    <Route exact={true} path="/check_ship_via">
                        <ShipViaCheckerContainer/>
                    </Route>

                    <Route exact={true} path="/packing_stats">
                        <PackingStatsContainer/>
                    </Route>


                    <Route path="/">
                        <Redirect to="/home"/>
                    </Route>

                </Switch>
            </Router>
        </div>
    );
};


export default AppRouter;

// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsconfig =  {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:723561c1-fb69-432f-bd64-f776865bbe88",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_lEfC5idmW",
    "aws_user_pools_web_client_id": "3bd3oiaha904brp00fogg5jkgq",
};


export default awsconfig;
import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router";
import useAuthUser from "../../Hooks/auth/useAuthUser";
import {Table, Select, DatePicker, Button, Spin, Icon} from "antd";
import moment from "moment";
import {
    authSuccessful,
    backendCall,
    roundToFixed2,
    utcStringToLocalString
} from "../../API/Utils";
import {SHIPPING_DASHBOARD_TABLE_COLUMNS} from "./ShippingDashboardTableColumns";
import {
    allShipmentStatuses,
    problematicShipmentStatuses,
    getColorForShipmentStatus
} from "./shipping-dashboard-config";
import {bold} from "../../Utils/HtmlUtils";
import {FilterManager, ShippingDashboardFilterProps} from "./FilterManager";
import ShipmentsDownloadButton from "../../Components/ActionButtons/ShipmentsDownloadButton";


const {RangePicker} = DatePicker;
const {Option} = Select;


const ShippingDashboardHomepageContainer = () => {

    // Needed to make sure unauthorized users are redirected to the login page
    const {authUser, authPending} = useAuthUser()

    // Shipment State Variables
    const [shipments, setShipments] = useState([]);
    const [loadingShipments, setLoadingShipments] = useState(true);

    // Stats State Variables
    const [stats, setStats] = useState({} as any);
    const [shipVias, setShipVias] = useState([]);
    const [rateZones, setRateZones] = useState([]);
    const [statsStartDate, setStatsStartDate] = useState('');
    const [statsEndDate, setStatsEndDate] = useState('');
    const [totalAnalyzedShipments, setTotalAnalyzedShipments] = useState(0);
    const [loadingStats, setLoadingStats] = useState(true);
    const [selectedStat, setSelectedStat] = useState('avg_delivery_time_days');
    const [statKeys, setStatKeys] = useState([] as any);

    // Page Config
    const pageSize = 60;
    const [numSearchResults, setNumSearchResults] = useState(9999);

    // Filter State
    const filterManagerRef = useRef(new FilterManager());
    const [filterState, setFilterState] = useState(filterManagerRef.current.getCurrentFilter())


    useEffect(() => {
        if (authSuccessful(authUser, authPending) && filterManagerRef.current.hasFilterChanged()) {
            console.log('Fetching Shipments...');
            fetchShipments(pageSize, filterState, filterManagerRef.current);
        }
    }, [
        authPending,
        authUser,
        filterState
    ]);

    useEffect(() => {
        if (authSuccessful(authUser, authPending)) {
            console.log('Fetching Stats...');
            fetchShippingStats();
        }
    }, [
        authPending,
        authUser
    ])

    const updateFilterState = (newValues: any) => {
        filterManagerRef.current.updateFilter(newValues)
        setFilterState(filterManagerRef.current.getCurrentFilter());
    }

    const handleSortOrPageChange = (pagination: any, filters: any, sorter: any) => {

        // Handle pagination, sorting validation
        let _sortDirection = filterState.sortDirection;
        let _sortColumn = filterState.sortColumn;
        let _pageNumber = pagination.current;

        if (sorter.order) {
            if (sorter.order === 'ascend') {
                _sortDirection = 'asc'
            } else {
                _sortDirection = 'desc'
            }
            _sortColumn = sorter.columnKey
        }


        // Update Filter State
        updateFilterState({
            pageNumber: _pageNumber,
            sortDirection: _sortDirection,
            sortColumn: _sortColumn
        })

    }

    const handleDateChange = (date: any, dateString: Array<string>) => {
        updateFilterState({
            startDate: dateString[0],
            endDate: dateString[1]
        })
    }

    const handleShipmentStatusSelection = (selectedOptions: any) => {
        updateFilterState({
            statusList: selectedOptions
        })
    }

    const fetchShippingStats = () => {
        setLoadingStats(true);

        backendCall('/get-shipping-stats', {
            is_test: '0',
            user_id: authUser.packer_username
        }, r => r).then((r: any) => {
                try {
                    if (r['result'] && r['result']['stats']) {
                        setTotalAnalyzedShipments(r['result']['stats']['total_analyzed_shipments'])
                        setStatsStartDate(r['result']['stats']['stats_start_date'])
                        setStatsEndDate(r['result']['stats']['stats_end_date'])
                        setShipVias(r['result']['stats']['ship_via_values'])
                        setRateZones(r['result']['stats']['rate_zone_values'])
                        setStats(r['result']['stats']['ship_via_stats'])
                        setStatKeys(r['result']['stats']['stat_keys'])
                    } else {
                        setStats({})
                    }

                } catch (e) {
                    console.log('Error = ', e)
                    alert(`Error fetching stats: ${e}`,)
                } finally {
                    setLoadingStats(false)
                }
            }
        )
    };

    const fetchShipments = (
        _pageSize: number,
        _filterState: ShippingDashboardFilterProps,
        _filterManager: FilterManager
    ) => {
        setLoadingShipments(true)

        backendCall('/get-filtered-shipments', {
            is_test: '0',
            user_id: authUser.packer_username,
            sort_column: _filterState.sortColumn,
            sort_direction: _filterState.sortDirection,
            page_size: _pageSize,
            page_number: _filterState.pageNumber,
            start_date: _filterState.startDate,
            end_date: _filterState.endDate,
            status_list: _filterState.statusList.join(',')
        }, r => r).then((r: any) => {
                try {
                    if (r['result'] && r['result']['shipments']) {
                        setShipments(r['result']['shipments'].map((shipment: any, index: number) => {
                            shipment['key'] = index;
                            return shipment
                        }))
                        setNumSearchResults(r['result']['total_shipments'])

                        // Getting rid of error in JS console
                        _filterManager.updateFilter({
                            numberOfEvents: r['result']['total_events'],
                            lastEventTime: r['result']['last_event_time'],
                            lastUpdateDate: r['result']['last_update_time']
                        })
                        setFilterState(_filterManager.getCurrentFilter())

                    } else {
                        setShipments([])
                    }


                } catch (e) {
                    console.log('Error = ', e)
                    alert(`Error fetching shipments: ${e}`,)
                } finally {
                    setLoadingShipments(false)
                }
            }
        )
    };


    return <div
        style={{
            marginTop: '1%',
            textAlign: 'center',
            width: '95%',
            marginLeft: '2.5%'
        }}
    >
        <div>
            <h1>Shipment Tracking</h1>
            <div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly'
                    }}

                >
                    <div
                        style={{
                            border: '1px solid #e8e8e8',
                            marginTop: '2%',
                            width: '40%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                        }}
                    >
                        <h2>Shipping Stats</h2>
                        <style>{`table, th, td{
                                         border:1px solid black;
                                        }`}
                        </style>
                        {loadingStats ? <Spin/> : <div
                        >
                            <div>
                                <div>
                                    <p>For {bold(totalAnalyzedShipments)} shipments delivered </p>
                                    <p>between {bold(statsStartDate.split(' ')[0])} and {bold(statsEndDate.split(' ')[0])}</p>

                                    <div
                                        style={{
                                            marginTop: '2%',
                                            marginBottom: '2%',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <h4>Selected Stat</h4>
                                        <Select
                                            defaultValue={selectedStat}
                                            style={{
                                                width: '50%',
                                            }}
                                            onChange={(value: string) => {
                                                setSelectedStat(value)
                                            }}
                                        >
                                            {statKeys.map((statKey: string, index: number) => {
                                                return <Option key={index} value={statKey}>{statKey}</Option>
                                            })}

                                        </Select>
                                    </div>
                                </div>
                                <table
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <tbody>
                                    <tr>
                                        <th>ShipVia / Zone</th>
                                        {rateZones.map((rateZone: number, index: number) => {
                                            return <th key={index}>{rateZone}</th>
                                        })}
                                    </tr>
                                    {shipVias.map((shipVia: string, i: number) => {
                                        return <tr key={i}>
                                            <td>{shipVia}</td>
                                            {rateZones.map((rateZone: string, j: number) => {
                                                    let num = stats[shipVia][rateZone][selectedStat]

                                                    if (num || num === 0) {
                                                        return <td key={j}>{roundToFixed2(num)}</td>
                                                    } else {
                                                        return <td key={j}></td>
                                                    }
                                                }
                                            )}
                                        </tr>
                                    })}
                                    </tbody>
                                </table>


                            </div>
                        </div>}

                    </div>
                    <div
                        style={{
                            border: '1px solid #e8e8e8',
                            marginTop: '2%',
                            width: '40%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                        }}
                    >
                        <h2>Filters</h2>
                        <div
                            style={{
                                marginBottom: '1%',
                                marginTop: '1%',
                                width: '90%',
                                marginLeft: '5%'
                            }}
                        >
                            {loadingShipments ? <Spin/> :<table
                                style={{
                                    width: '100%'
                                }}
                            >
                                <tbody>
                                <tr>
                                    <td>{bold('Data Update Frequency: ')}</td>
                                    <td>{bold('1x')} per {bold('20')} minutes</td>
                                </tr>
                                <tr>
                                    <td>{bold('Last Event Time:')}</td>
                                    <td>{utcStringToLocalString(filterState.lastEventTime)}</td>
                                </tr>
                                <tr>
                                    <td>{bold('Last Update:')}</td>
                                    <td>{utcStringToLocalString(filterState.lastUpdateDate)}</td>
                                </tr>
                                </tbody>
                            </table>}

                        </div>

                        <div
                            style={{
                                marginBottom: '1%',
                                marginTop: '1%',
                                marginLeft: '5%',
                                width: '90%'
                            }}
                        >
                            <h4>Range for Ship Date</h4>
                            <RangePicker
                                style={{
                                    width: '100%'
                                }}
                                onChange={handleDateChange}
                                value={[moment(filterState.startDate), moment(filterState.endDate)]}
                            />

                        </div>
                        <div
                            style={{
                                marginBottom: '1%',
                                marginTop: '1%',
                                marginLeft: '5%',
                                width: '90%'
                            }}
                        >
                            <h4>Allowed Shipment Status</h4>
                            <Select
                                mode="multiple"
                                placeholder="Shipment Satus"
                                defaultValue={filterState.statusList}
                                value={filterState.statusList}
                                onChange={handleShipmentStatusSelection}
                                style={{
                                    width: '100%'
                                }}
                            >
                                {allShipmentStatuses.map((shipmentStatus: string) => (
                                    <Option
                                        key={shipmentStatus}
                                        value={shipmentStatus}
                                        style={{
                                            color: getColorForShipmentStatus(shipmentStatus),
                                            fontWeight: 'bold',
                                            textAlign: 'center'
                                        }}
                                    >{shipmentStatus}</Option>
                                ))}
                            </Select>
                        </div>
                        <div
                            style={{
                                marginBottom: '1%',
                                marginTop: '1%'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    marginLeft: '5%',
                                    width: '90%',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Button
                                    type={'danger'}
                                    onClick={() => {
                                        updateFilterState({
                                            statusList: problematicShipmentStatuses
                                        })
                                    }}
                                    disabled={loadingShipments}
                                >
                                    Select Problematic
                                    <Icon type={'warning'}/>
                                </Button>


                                <Button
                                    type={'default'}
                                    onClick={() => {
                                        updateFilterState({
                                            statusList: allShipmentStatuses
                                        })
                                    }}
                                    disabled={loadingShipments}
                                >
                                    Select All
                                    <Icon type={'check-circle'}/>
                                </Button>

                                <ShipmentsDownloadButton
                                    filterState={filterState}
                                />

                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <Table
                        dataSource={shipments}
                        columns={SHIPPING_DASHBOARD_TABLE_COLUMNS}
                        pagination={{
                            pageSize: pageSize,
                            current: +filterState.pageNumber,
                            total: +numSearchResults,
                            position: 'both',
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} shipments (${filterState.numberOfEvents} tracking events)`
                        }}
                        loading={loadingShipments}
                        onChange={handleSortOrPageChange}
                        scroll={{
                            scrollToFirstRowOnChange: true
                        }}

                    />
                </div>

            </div>

        </div>


    </div>


}
export default withRouter(ShippingDashboardHomepageContainer);

import React from "react";
import {ItemOperationRouterProps} from "./ItemOperationProps";
import {ItemOperationEnum} from "../../Packing/Operation/ItemOperationEnum";
import ScanItemInput from "./ScanItemInput";
import MoveItemInput from "./MoveItemInput";

const ItemOperationRouter: React.SFC<ItemOperationRouterProps> = (props) => {
    return (
        <div>
            {
                ItemOperationEnum.SCAN_ITEM === props.itemOperationEnum ?
                    <ScanItemInput
                        itemManager={props.itemManager}
                        packingStationId={props.packingStationId}
                        loading={props.loading}
                        scannedItemUpc={props.scannedItemUpc}
                        focusToScanInput={props.focusToScanInput}
                        setScannedItemUpc={props.setScannedItemUpc}
                        currentNumScans={props.currentNumScans}
                        isActive={ItemOperationEnum.SCAN_ITEM === props.itemOperationEnum}
                    /> : null
            }

            {
                ItemOperationEnum.MOVE_ITEM === props.itemOperationEnum ?
                    <MoveItemInput
                        itemManager={props.itemManager}
                        packingStationId={props.packingStationId}
                        loading={props.loading}
                        scannedItemUpc={props.scannedItemUpc}
                        focusToScanInput={props.focusToScanInput}
                        setScannedItemUpc={props.setScannedItemUpc}
                        currentNumScans={props.currentNumScans}
                        isActive={ItemOperationEnum.MOVE_ITEM === props.itemOperationEnum}
                    /> : null
            }
        </div>
    )
}

export default ItemOperationRouter

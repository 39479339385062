export interface ApiEnv{
    httpEndpoint: string;
    websocketEndpoint: string;
}

export const getApiEnv = (): ApiEnv => {
    return getProdApiEnv();

}

export const getLocalApiEnv = (): ApiEnv => {
    const endpoint = '127.0.0.1:5001'

    return {
        httpEndpoint: `http://${endpoint}`,
        websocketEndpoint: `ws://${endpoint}`
    }
}

export const getProdApiEnv = (): ApiEnv => {
    const endpoint = 'backend.perseuss.xyz'

    return {
        httpEndpoint: `https://${endpoint}`,
        websocketEndpoint: `wss://${endpoint}`
    }
}



import * as React from 'react';
import "@fontsource/urbanist";

/** Components */
import AppRouter from '../Navigation/Routes';

/** Ant design stylesheet */
import 'antd/dist/antd.css';

const App: React.FC = () => {

    return <div>
        <AppRouter/>
    </div>

};

export default App;

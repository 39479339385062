import {Button, Icon} from "antd";
import * as React from "react";
import {backendCall} from "../../API/Utils";

type MedusaProps = {
    orderId: string,
    hideOrderId?: boolean,
    user_id?: string,
}


const MedusaOrderButton: React.SFC<MedusaProps> = (props) => {
    const [loading, setLoading] = React.useState(false)

    const handleClick = () => {
        setLoading(true)
        backendCall('/get-order-header-id', {
            is_test: '0',
            user_id: props.user_id,
            order_id: props.orderId,
        }, r => r).then((r: any) => {
            setLoading(false);
            try{
                const orderHeaderId = r['result']['order_header_id']
                const url = `https://med.deposco.com/deposco/entity/detail;id=${orderHeaderId};entity=OrderHeader;bk=${orderHeaderId};tabIndex=0;currentLayoutId=undefined`
                window.open(url, "_blank", "noreferrer");
            } catch (e) {
                console.log(e)
            }


        })
    }

    return <div>
        <Button
            loading={loading}
            disabled={loading || !props.orderId}
            type={'danger'}
            onClick={handleClick}
        >

            {
                props.hideOrderId ? '' : `${props.orderId}`
            }
            <Icon type={'link'}/>
        </Button>
    </div>
}

export default MedusaOrderButton

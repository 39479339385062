import {todayInYYYYMMDD, todayInYYYYMMDDMinusDays} from "../../API/Utils";
import {problematicShipmentStatuses} from "./shipping-dashboard-config";

export class FilterManager {
    private currentFilter: ShippingDashboardFilterProps;
    private oldFilter: ShippingDashboardFilterProps;
    private numChanges: number;
    private defaultFilter: ShippingDashboardFilterProps = {
        pageNumber: 1,
        sortDirection: 'desc',
        sortColumn: 'deposco_ship_date',
        startDate: todayInYYYYMMDDMinusDays(7),
        endDate: todayInYYYYMMDD(),
        statusList: problematicShipmentStatuses,
        numberOfEvents: 0,
        lastEventTime: '',
        lastUpdateDate: ''
    } as ShippingDashboardFilterProps

    constructor() {
        this.currentFilter = this.defaultFilter;
        this.oldFilter = this.currentFilter;
        this.numChanges = 0;
    }

    public updateFilter = (newValues: any) => {
        this.oldFilter = this.currentFilter;
        this.currentFilter = {
            ...this.currentFilter,
            ...newValues
        }
        this.numChanges += 1;
    }

    public getCurrentFilter = (): ShippingDashboardFilterProps => {
        return this.currentFilter
    }

    public hasFilterChanged = () => {
        return this.currentFilter.endDate !== this.oldFilter.endDate ||
            this.currentFilter.startDate !== this.oldFilter.startDate ||
            this.currentFilter.sortDirection !== this.oldFilter.sortDirection ||
            this.currentFilter.sortColumn !== this.oldFilter.sortColumn ||
            this.currentFilter.statusList !== this.oldFilter.statusList ||
            this.currentFilter.pageNumber !== this.oldFilter.pageNumber ||
            this.numChanges === 0;
    }


}

export type ShippingDashboardFilterProps = {
    pageNumber: number,
    sortDirection: string,
    sortColumn: string,
    startDate: string,
    endDate: string,
    statusList: Array<string>,
    numberOfEvents: number,
    lastEventTime: string,
    lastUpdateDate: string,
}


import {Spin} from "antd";
import React from "react";
import {ItemOperationResult} from "../../Packing/Operation/ItemOperationResult";
import {bold, boldGreen, boldRed} from "../../Utils/HtmlUtils";

type ScanResultViewProps = {
    itemOperationResult: ItemOperationResult,
    loading: boolean
}


const ScanResultView: React.SFC<ScanResultViewProps> = (props) => {
    return (
        <div>
            {props.loading ? <Spin></Spin> :
                <div>
                    <div

                    >

                        <div
                            style={{
                                borderBottom: '1px solid #1890ff',
                                height: "57px",
                                paddingTop: "2%",
                            }}

                        >
                            <h2>{bold('Scan Result')}</h2>
                        </div>

                        <div
                            style={{
                                padding: '2%',
                                fontSize: '1.5em',
                            }}

                        >
                            {props.itemOperationResult.shortErrorMessage ?
                                <h1>{boldRed(props.itemOperationResult.shortErrorMessage)}</h1> :
                                <h1>{boldGreen(props.itemOperationResult.shortInfoMessage)}</h1>}

                            {props.itemOperationResult.longErrorMessages.map((m: string, index: number) => {
                                return <h1 key={index}>{boldRed(m.split(':')[0])}: {m.split(':')[1]}</h1>
                            })}

                            {props.itemOperationResult.longInfoMessages.map((m: string, index: number) => {
                                return <h1 key={index}>{boldGreen(m.split(':')[0])}: {m.split(':')[1]}</h1>
                            })}
                        </div>

                    </div>
                </div>
            }

        </div>
    )
}

export default ScanResultView
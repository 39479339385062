import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import {useParams} from "react-router-dom";
import {Button, Spin, Table} from "antd";
import {authSuccessful, backendCall, getRedAndGreenColorValues} from "../../API/Utils";
import useAuthUser from "../../Hooks/auth/useAuthUser";
import MedusaOrderButton from "../../Components/ActionButtons/MedusaOrderButton";
import {OLD_SHIPMENT_TABLE_COLUMNS} from "../../Components/TableColumns/OldShipmentTableColumns";

interface ToteSimulationDetailsContainerProps {
    orderId: string,
    toteId: string
}


const ToteSimulationDetailsContainer = () => {
    const {authUser, authPending} = useAuthUser()
    const {orderId, toteId} = useParams<ToteSimulationDetailsContainerProps>();

    const [actualsData, setActualsData] = useState([]);
    const [simulatedData, setSimulatedData] = useState([]);
    const [toteData, setToteData] = useState({} as any);

    const [loading, setLoading] = useState(true);
    const [profitColors, setProfitColors] = useState([] as any);


    useEffect(() => {
        if (authSuccessful(authUser, authPending)) {
            backendCall('/get-simulated-tote-details', {
                is_test: '0',
                simulation_id: 'Medusa_POC_2023_07_19',
                order_id: orderId,
                tote_id: toteId
            }, r => r).then((r: any) => {
                try {
                    let result = r['result']['result']

                    setToteData(result['tote_metadata'])

                    setActualsData(result['actual_shipments'].map((shipment: any, index: number) => {
                        return {
                            ...shipment,
                            key: index
                        }
                    }))

                    setSimulatedData(result['simulated_shipments'].map((shipment: any, index: number) => {
                        return {
                            ...shipment,
                            key: index
                        }
                    }))


                    setProfitColors(getRedAndGreenColorValues(result['tote_metadata']['savings_pctg']))
                } catch (e) {
                    console.log(e)
                    alert(e)
                }

                setLoading(false);
            })
        }


    }, [authUser, authPending, orderId, toteId])


    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly'
                }}
            >
                <div
                    style={{
                        width: '15%',
                        marginTop: '30px'
                    }}
                >
                    <div>
                        <div
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            <h2>Tote Details</h2>
                        </div>
                        {loading ? <Spin/> : <div>
                            <table
                                style={{
                                    width: '100%',
                                }}
                            >
                                <tbody>
                                <tr>
                                    <td><span style={{fontWeight: 'bold'}}>Order ID: </span></td>
                                    <td>
                                        <MedusaOrderButton orderId={toteData['order_id']}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span style={{fontWeight: 'bold'}}>Tote ID: </span></td>
                                    <td>
                                        <a href={`/order_id/${toteData['order_id']}/tote_id/${toteData['tote_id']}`}
                                           target={'_blank'}>
                                            <Button
                                                type={'primary'}
                                            >{toteData['tote_id']}</Button>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            height: '15px'
                                        }}
                                    ></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><span style={{fontWeight: 'bold'}}>Zip Code: </span></td>
                                    <td>{toteData['destination_zip_code']}</td>
                                </tr>
                                <tr>
                                    <td><span style={{fontWeight: 'bold'}}>Zone: </span></td>
                                    <td>{toteData['zone']}</td>
                                </tr>
                                <tr>
                                    <td><span style={{fontWeight: 'bold'}}>Num Items: </span></td>
                                    <td>{toteData['num_items']}</td>
                                </tr>
                                <tr>
                                    <td><span style={{fontWeight: 'bold'}}>Weight: </span></td>
                                    <td>{toteData['weight_to_ship']} lbs</td>
                                </tr>
                                <tr>
                                    <td><span style={{fontWeight: 'bold'}}>Volume: </span></td>
                                    <td>{toteData['volume_to_ship']} cb_ft</td>
                                </tr>
                                <tr>
                                    <td><span style={{fontWeight: 'bold'}}>Old Cost: </span></td>
                                    <td>${toteData['old_cost']}</td>
                                </tr>
                                <tr>
                                    <td><span style={{fontWeight: 'bold'}}>New Cost: </span></td>
                                    <td>${toteData['new_cost']}</td>
                                </tr>
                                <tr>
                                    <td><span style={{fontWeight: 'bold'}}>Savings: </span></td>
                                    <td>
                                        <span style={{
                                            fontWeight: 'bold',
                                            color: `rgb(${profitColors[0]}, ${profitColors[1]}, 0)`
                                        }}>${toteData['savings']}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span style={{fontWeight: 'bold'}}>% of Savings: </span></td>
                                    <td>
                                        <span style={{
                                            fontWeight: 'bold',
                                            color: `rgb(${profitColors[0]}, ${profitColors[1]}, 0)`
                                        }}>{toteData['savings_pctg']}%</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>}

                    </div>

                </div>
                <div
                    style={{
                        width: '70%',
                        marginTop: '30px'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                        }}
                    >
                        <h1>Actuals</h1>
                        <h2>{actualsData.length} Shipments</h2>
                        <Table
                            dataSource={actualsData}
                            columns={OLD_SHIPMENT_TABLE_COLUMNS}
                            loading={loading}

                        />
                    </div>
                    <div
                        style={{
                            width: '100%',
                        }}
                    >
                        <h1>Simulated</h1>
                        <h2>{simulatedData.length} Shipments</h2>
                        <Table
                            dataSource={simulatedData}
                            columns={OLD_SHIPMENT_TABLE_COLUMNS}
                            loading={loading}
                        />
                    </div>
                </div>

            </div>
        </div>

    )

}

export default withRouter(ToteSimulationDetailsContainer);

export const cleanCategoryMap = {
  "e-Liquid": "Vape Juice",
  "Disposables": "Hardware Battery",
  "Water Pipes": "",
  "Pod Systems": "Hardware Battery",
  "e-Liquid (Salt Nic)": "Vape Juice",
  "Hand Pipes": "",
  "Starter Kit": "Hardware Battery",
  "Tanks": "Hardware Non-Battery",
  "Coils": "Coils",
  "Regulated Box Mod": "Hardware Non-Battery",
  "Grinders": "",
  "510 Vaporizers": "Hardware Battery",
  "Bangers": "",
  "Starter Kits": "Hardware Battery",
  "Accessories": "",
  "Pods": "Coils",
  "Drip Tips": "Coils",
  "Delta 8 - GGG": "Hardware Battery",
  "Rolling Trays": "",
  "RDA": "Hardware Non-Battery",
  "Bowls": "",
  "Regulated Box Mods": "Hardware Non-Battery",
  "Apparel": "",
  "Storage": "",
  "Replacement Glass": "Coils",
  "Wax/Concentrate Vaporizers": "Hardware Battery",
  "Carb Caps": "",
  "Delta 9 - GGG": "Hardware Battery",
  "Cones": "",
  "Smoking Accessories": "",
  "Rolling Papers": "",
  "Candles": "",
  "RTA": "Hardware Non-Battery",
  "Torches": "",
  "Bubblers": "",
  "Glass Accessories": "",
  "Dab Rigs": "",
  "CBD Edibles - GGG": "",
  "Hemp Enhancer - GGG": "Vape Juice",
  "Incense": "",
  "Replacement Parts": "",
  "Ash Catchers": "",
  "Herbal Vaporizers": "Hardware Battery",
  "Pre-Filled Pods": "Vape Juice",
  "test product check": "",
  "Air Fresheners": "",
  "Dab Tools": "",
  "Herb/Wax Vaporizers": "Hardware Battery",
  "Batteries": "",
  "Cleaning Products": "Vape Juice",
  "Battery Chargers": "",
  "Dab Mats": "",
  "Nicotine Substitutes": "Vape Juice",
  "Squonk Mods": "Hardware Non-Battery",
  "Wraps": "",
  "Scales": "",
  "Pre-Filled Lighters": "",
  "Hookah Shisha - GGG": "",
  "Ashtrays": "",
  "Incense Burners": "",
  "Dugouts": "",
  "Chillums": "",
  "e-Juice": "Vape Juice",
  "Down Stems": "",
  "CBD Flower - GGG": "",
  "Herbal": "",
  "Canna Coils": "Coils",
  "THC-O - GGG": "Hardware Battery",
  "Tips": "",
  "RDTA": "Coils",
  "Hookah Accessories": "",
  "Hookah Pipes - GGG": "",
  "Stash": "",
  "Promotional": "",
  "510 Tanks": "Hardware Battery",
  "Components": "",
  "Fidget Spinners": "",
  "Hookah": "",
  "CBD Disposables - GGG": "Hardware Battery",
  "Canna Accessories": "",
  "E-Nails": "Hardware Non-Battery",
  "Von Erl": "",
  "Rolling Machines": "",
  "Detox": "",
  "DIY": "",
  "Herbal/Wax Vaporizers": "Hardware Battery",
  "Pre-Built Coils": "Coils",
  "HHC - GGG": "Hardware Battery",
  "Nails": "",
  "Smoke Filters": "",
  "CBD E-liquid - GGG": "Vape Juice",
  "CBD Topicals - GGG": "",
  "Collections": "",
  "Delta 10  - GGG": "Hardware Battery",
  "GGV Merch - GGG": "",
  "Hookah Bowls": "",
  "GGG - Rolling Papers": "",
  "Store Accessories": "",
  "Kratom Capsules - GGG": "",
  "Mechanical Mods": "Hardware Non-Battery",
  "Novelty": "",
  "CBD Tincture - GGG": "Vape Juice",
  "Kratom Powder - GGG": "",
  "Lighters": "",
  "GGG - Smoke Accessories": "",
  "CBD Pre-Roll - GGG": "",
  "Hookah Charcoal": "",
  "Cotton Wicks": "",
  "GGG-Smoke Accessories": "",
  "GGG-Water Pipes": "",
  "Butane": "",
  "Cigalikes": "",
  "Hookah Charcoal - GGG": "",
  "Delta 11 - GGG": "Hardware Battery",
  "e-juice": "Vape Juice",
  "Welcome Box": "",
  "QA test product": "",
  "CBG Flower - GGG": "",
  "VON ERL MY": "Hardware Battery",
  "Kratom Edibles - GGG": "",
  "Lighter Accessories": "",
  "Gift Card": "",
  "GGG-Lighters": "",
  "Test Live product": "",
  "Cream Accessories -  GGG": "",
  "GGG - Rolling Paper": "",
  "Butane - GGG": "",
  "CBG Flower  - GGG": "",
  "E-liquid": "Vape Juice",
  "Supplements - GGG": "",
  "HHCP - GGG": "",
  "GGG-Bangers": "",
  "CBG Pre-Roll - GGG": "",
  "CBD Inhaler - GGG": "",
  "CBD Capsules - GGG": "",
  "Lighter Fluids": "",
  "Herbal Accessories": "",
  "Edibles - GGG": "",
  "Coil Head Packs": "Coils",
  "GGG - Lighters": "",
  "CBD Accessories - GGG": "",
  "Test": "",
  "Flints": "",
  "GGG-Hand Pipes": "",
  "GGG - Handpipes": "",
  "Test medusa category": "",
  "CBD Tank": "Vape Juice",
  "Test category": "",
  "Clearomizer - GGG": "",
  "E liquid": "Vape Juice",
  "GGG - Bowls/Slides": "",
  "DabTools": "",
  "Chargers": "",
  "Silicone Mod Sleeves": "Coils"
} as any;
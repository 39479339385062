import React, {useEffect} from "react";
import PackerStatsView from "../../Components/Shared/PackerStatsView";
import useAuthUser from "../../Hooks/auth/useAuthUser";
import {authSuccessful} from "../../API/Utils";
import {UserService} from "../../Service/UserService";
import { Spin } from "antd";


const PackingStatsContainer = () => {

    const {authUser, authPending} = useAuthUser();
    const [loading, setLoading] = React.useState(true);
    const [registeredPackers, setRegisteredPackers] = React.useState([] as string[]);

    useEffect(() => {
        if (authSuccessful(authUser, authPending)) {
            new UserService(authUser.packer_username).getAllProdPackersAsync().then((data) => {
                setRegisteredPackers(data.result.users);
                setLoading(false);
            })
        }

    }, [authUser, authPending])

    const numPackersPerRow = 3;


    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                flexWrap: 'wrap'
            }}
        >
            {loading ? <Spin style={{marginTop: '10%'}}/> : registeredPackers.map((user: any, index: number) => {
                return <div
                    key={index}
                    style={{
                        width: `${90 / numPackersPerRow}%`,
                        marginTop: '1%',

                    }}
                >
                    <PackerStatsView
                        authUser={{
                            email: `${user.packer_username}@perseuss.xyz`,
                            full_name: user.full_name,
                            packer_username: user.packer_username,
                            photo_url: user.photo_url,
                            packing_station_index: user.packing_station_index
                        }}
                        rawStats={user.packer_stats}
                    />
                </div>
            })}
        </div>
    )

}

export default PackingStatsContainer;

import React, {useEffect, useState} from "react";
import {Button, Input, Spin} from "antd";
import {
    authSuccessful,
    backendCall,
} from "../../API/Utils";
import useAuthUser from "../../Hooks/auth/useAuthUser";
import {bold, boldBlue, boldRed} from "../../Utils/HtmlUtils";
import {withRouter} from "react-router-dom";

const ResetItemsContainer = () => {
    const [loading, setLoading] = useState(false);
    const {authUser, authPending} = useAuthUser();

    const [originalToteId, setOriginalToteId] = useState('');
    const [newBoxId, setNewBoxId] = useState('');

    const [orderId, setOrderId] = useState('');
    const [moveItemResults, setMoveItemResults] = useState([]);
    const [error, setError] = useState('');


    useEffect(() => {
        if (authSuccessful(authUser, authPending)) {
            console.log('Auth successful');
        }
    }, [authUser, authPending])


    const handleClick = () => {
        setLoading(true);
        backendCall('/reset-items', {
            original_container: originalToteId,
            new_container: newBoxId,
            user_id: authUser.packer_username
        }, r => r).then((response) => {
                if (response.result) {
                    setOrderId(response.result.reset_params.order_id);
                    setMoveItemResults(response.result.move_item_results);
                } else {
                    setOrderId('Error... Maybe wrong tote id or container id?');
                    setError(JSON.stringify(response));
                }
                setLoading(false);
            }
        )
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginTop: '5%',
                marginLeft: '25%',
                width: '50%',
                height: '150px',
                textAlign: 'center'
            }}
        >
            <div>
                <h1>Reset Items</h1>
            </div>
            <div
                style={{
                    marginTop: '3%',
                    width: '60%',
                    marginLeft: '20%'
                }}
            >
                <h3>Original Tote ID</h3>
                <p>The tote that you scanned at the beggining of the packing</p>
                <Input
                    value={originalToteId}
                    onChange={(e) => {
                        setOriginalToteId(e.target.value)
                    }}
                >
                </Input>
            </div>

            <div

                style={{
                    marginTop: '3%',
                    width: '60%',
                    marginLeft: '20%'
                }}
            >
                <h3>New Box ID</h3>
                <p>The box ID that is giving you a hard time during check out</p>
                <Input
                    value={newBoxId}
                    onChange={(e) => {
                        setNewBoxId(e.target.value)
                    }}
                >
                </Input>
            </div>

            <div
                style={{
                    marginTop: '3%',
                }}
            >

                <div
                    style={{
                        marginTop: '3%',
                    }}

                >
                    <p>{bold('Important: ')}Clicking this button will move all items.</p>
                    <p>{bold('FROM:')} {boldRed(newBoxId)} </p>
                    <p>{bold('BACK TO: ')}{boldBlue(originalToteId)}</p>

                </div>

                <Button
                    onClick={handleClick}
                    type={'primary'}
                    loading={loading}
                    disabled={loading || !originalToteId || !newBoxId}
                >
                    Reset Items
                </Button>


            </div>

            {orderId ? <div
                style={{
                    marginTop: '3%',
                }}
            >
                <h3> Result </h3>
                {loading ? <div><Spin/></div> : <div>
                    <p>{bold('Order Id: ')} {orderId}</p>
                    <p>{bold('Move Item Results: ')}</p>
                    <div>
                        {JSON.stringify(moveItemResults)}
                    </div>

                    {error ? null : <p>{bold('Error: ')} {error}</p>}
                </div>}

            </div> : null}
        </div>

    )

}

export default withRouter(ResetItemsContainer);

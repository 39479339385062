import React, {useEffect, useState} from "react";
import {Button, Select, Table} from "antd";
import {
    authSuccessful, backendCall, columnTitle, roundToFixed2,
} from "../../API/Utils";
import {bold,} from "../../Utils/HtmlUtils";
import {withRouter} from "react-router-dom";
import useAuthUser from "../../Hooks/auth/useAuthUser";

const {Option} = Select;


const TABLE_COLKUMNS = [
    {
        title: columnTitle('Product', ''),
        dataIndex: 'product_id',
        key: 'product',
        render: (text: any, record: any) => {
            return <div>
                <p>{bold('Product Name: ')} {record.product_name}</p>
                <p>{bold('Product Sku: ')} {record.sku}</p>
                <p>{bold('Product ID:')} {record.product_id}</p>
                <p>{bold('Created At:')} {record.variant_created_at?.substring(0, 16)}</p>
                <p>{bold('Landed At:')} {record.last_landed_date?.substring(0, 16)}</p>

            </div>
        }

    },
    {
        title: columnTitle('', ''),
        dataIndex: 'product_id',
        key: 'thumbnail',
        render: (text: any, record: any) => {
            return <div>
                <img
                    src={record.image_thumbnail}
                    style={{
                        width: '100px',
                        height: '100px'
                    }}
                    alt={record.product_name}
                >

                </img>
            </div>
        }

    },
    {
        title: columnTitle('Total Stats', ''),
        dataIndex: 'product_id',
        key: 'product_stats',
        width: '20%',
        render: (text: any, record: any) => {
            return <div>
                <p>{bold('Total Product Views: ')} {record.total_product_views}</p>
                <p>{bold('Total Units Sold: ')} {record.total_units_sold}</p>
                <p>{bold('Total Product Revenue')} ${roundToFixed2(record.total_revenue)}</p>
            </div>
        }
    },

    {
        title: columnTitle('Company Stats', ''),
        dataIndex: 'ground_truth',
        key: 'ground_truth',
        width: '20%',
        render: (ground_truth: any, record: any) => {
            return <div>
                <p>{bold('Company Product Views: ')} {ground_truth.total_product_views}</p>
                <p>{bold('Company Units Bought: ')} {ground_truth.total_units_sold}</p>
                <p>{bold('Company Product Spend')} ${roundToFixed2(ground_truth.total_revenue)}</p>
            </div>
        }
    },

    {
        title: columnTitle('Sort Info', ''),
        dataIndex: 'product_id',
        key: 'sort_info',
        width: '20%',
        render: (text: any, record: any) => {
            return <div>
                <p>{bold('Position: ')} {record.position}</p>
                <p>{bold('Purchase Probability: ')} {roundToFixed2(100 * record.atc_prob)}%</p>
                <p>{bold('Product Price: ')} ${roundToFixed2(record.price)}</p>
                <p>{bold('Expected Value: ')} ${roundToFixed2(record.expected_value)}</p>
            </div>
        }

    },
]

const RecSysContainer = () => {
    const [loading, setLoading] = useState(true);
    const {authUser, authPending} = useAuthUser();
    const [companies, setCompanies] = useState([] as any);

    const [selectedCompanyId, setSelectedCompanyId] = useState('');
    const [selectedCompanyDetails, setSelectedCompanyDetails] = useState({} as any);
    const [numRecommendations, setNumRecommendations] = useState(30);

    const [maxNumViewsBeforePurchase, setMaxNumViewsBeforePurchase] = useState(10);
    const [sortKey, setSortKey] = useState('atc_prob');

    const [allowedSortKeys, setAllowedSortKeys] = useState([
        {key: 'expected_value', value: 'Expected Value'},
        {key: 'atc_prob', value: 'Purchase Probability'},
    ]);


    const [recommendedProducts, setRecommendedProducts] = useState([] as any);
    const [inferenceMethod, setInferenceMethod] = useState('');


    useEffect(() => {
        setLoading(true);
        if (authSuccessful(authUser, authPending)) {
            backendCall('/get-companies-for-recsys', {}, r => r).then((response) => {
                if (response.result) {
                    setCompanies(response.result);
                    setNumRecommendations(numRecommendations);
                    setSelectedCompanyId(response.result[0].company_id);
                }

                setLoading(false);
            })
        }
    }, [authUser, authPending, numRecommendations])


    const handleClick = () => {
        setLoading(true);
        backendCall('/get-new_arrivals-recs', {
            user_id: authUser.packer_username,
            company_id: selectedCompanyId,
            number_of_recommendations: numRecommendations,
            max_views_before_purchase: maxNumViewsBeforePurchase,
            sort_key: sortKey
        }, r => r).then((response) => {
                if (response.result) {
                    setRecommendedProducts(response.result.selected_products.map(
                        (product: any, index: number) => {
                            return {
                                ...product,
                                key: index
                            }
                        }
                    ));
                    setSelectedCompanyDetails(response.result.company_data);
                    setInferenceMethod(response.result.inference_method);
                    console.log('Num Companies = ', companies.length);

                }
                setLoading(false);
            }
        )
    }

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    marginTop: '1%',
                    marginLeft: '25%',
                    width: '50%',
                    textAlign: 'center',
                    height: '200px'
                }}
            >
                <div>
                    <h1>RecSys Config Panel</h1>
                </div>


                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: '100%',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <label>
                            {bold('Company ID')}
                        </label>

                        <div
                            style={{
                                width: '60%'
                            }}
                        >
                            <Select
                                showSearch
                                disabled={loading}
                                placeholder="Company"
                                defaultValue={selectedCompanyId}
                                value={selectedCompanyId}
                                onChange={(value: any) => setSelectedCompanyId(value)}

                                filterOption={(input, option: any) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }


                                style={{
                                    width: '100%'
                                }}
                            >
                                {companies.map((company: any) => {
                                    return <Option
                                        key={company.company_id}
                                        value={company.company_id}
                                    >{company.company_name}</Option>
                                })}
                            </Select>
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <label>
                            {bold('Sort Key')}
                        </label>

                        <div
                            style={{
                                width: '60%'
                            }}
                        >
                            <Select
                                disabled={loading}
                                placeholder="Sort Key"
                                defaultValue={sortKey}
                                value={sortKey}
                                onChange={(value: any) => setSortKey(value)}


                                style={{
                                    width: '100%'
                                }}
                            >
                                {allowedSortKeys.map((sort_key: any) => {
                                    return <Option
                                        key={sort_key.key}
                                        value={sort_key.key}
                                    >{sort_key.value}</Option>
                                })}
                            </Select>
                        </div>
                    </div>


                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <label>
                            {bold('Max Views Before Purchase')}
                        </label>

                        <div
                            style={{
                                width: '60%'
                            }}
                        >
                            <Select
                                showSearch
                                disabled={loading}
                                placeholder="Max Views Before Purchase"
                                defaultValue={maxNumViewsBeforePurchase}
                                value={maxNumViewsBeforePurchase}
                                onChange={(value: any) => setMaxNumViewsBeforePurchase(value)}

                                filterOption={(input, option: any) =>
                                    option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                }

                                style={{
                                    width: '100%'
                                }}
                            >
                                {Array.from({length: 99}, (_, i) => i + 1)
                                    .map((num: number) => {
                                        return <Option
                                            key={num}
                                            value={num}
                                        >{num}</Option>
                                    })}
                            </Select>
                        </div>
                    </div>


                    <Button
                        type={'primary'}
                        onClick={handleClick}
                        disabled={loading}
                        loading={loading}
                        style={{
                            width: '50%',
                            marginLeft: '25%',
                        }}
                    >
                        Get Recommendations
                    </Button>
                </div>

            </div>
            <div
                style={{
                    marginTop: '3%',
                    marginLeft: '15%',
                    width: '70%',
                    textAlign: 'center'
                }}
            >


                {(Object.keys(selectedCompanyDetails).length > 0) ? <div>

                    <h2
                        style={{
                            marginBottom: '3%'
                        }}

                    >{selectedCompanyDetails.company_name}</h2>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: '3%',
                            marginTop: '3%'
                        }}

                    >

                        <div>
                            <h3>Company Details</h3>
                            <p>{bold('Model: ')} {inferenceMethod}</p>
                            <p>{bold('Company Id: ')} {selectedCompanyId}</p>
                            <p>{bold('Company Total View Count: ')} {selectedCompanyDetails.total_product_views}</p>
                            <p>{bold('Company Total Sales: ')} {selectedCompanyDetails.total_units_sold}</p>
                            <p>{bold('Company Total Revenue: ')} ${roundToFixed2(selectedCompanyDetails.total_revenue)}</p>


                        </div>

                        <div>
                            <h3>Similar Companies</h3>
                            {
                                selectedCompanyDetails.similar_companies.map((company: any, index: number) => {
                                    return <p key={index}>
                                        {bold(company.company_name)}: {company.embedding_similarity} similar
                                    </p>
                                })
                            }
                        </div>
                    </div>

                    <Table
                        columns={TABLE_COLKUMNS}
                        dataSource={recommendedProducts}
                        loading={loading}
                        pagination={{pageSize: 100}}
                    >

                    </Table>


                </div> : null}

            </div>
        </div>

    );

}

export default withRouter(RecSysContainer);

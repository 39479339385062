import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {withRouter} from "react-router";
import {Button, Checkbox, Icon, Menu, Spin, Table} from "antd";
import {authSuccessful, backendCall} from "../../API/Utils";
import {getShipmentTableColumns} from "../../Components/TableColumns/ShipmentTableColumns";
import useAuthUser from "../../Hooks/auth/useAuthUser";
import {bold} from "../../Utils/HtmlUtils";
import {ItemManager} from "../../Packing/ItemManager";
import {ItemOperationResult} from "../../Packing/Operation/ItemOperationResult";
import ToteInfoView from "../../Components/Shared/ToteInfoView";
import ScanResultView from "../../Components/Shared/ScanResultView";
import {ItemOperationEnum} from "../../Packing/Operation/ItemOperationEnum";
import ItemOperationRouter from "../../Components/ScanOperation/ItemOperationRouter";
import {AuthService} from "../../Service/AuthService";

interface PackSolutionContainerProps {
    solutionId: string
}


const PackSolutionContainer = () => {
    // This hook is important because even though the result is not used
    // it is used to check if the user is authenticated
    const {authUser, authPending} = useAuthUser()

    const [loading, setLoading] = useState<boolean>(false)
    const [focusToScanInput, setFocusToScanInput] = useState<boolean>(true)
    const [selectedPackingOperation, setSelectedPackingOperation] = useState<ItemOperationEnum>(ItemOperationEnum.SCAN_ITEM)

    const {solutionId} = useParams<PackSolutionContainerProps>()
    const [solutionData, setSolutionData] = useState<any>({})
    const [shipments, setShipments] = useState<any[]>([])

    const [packingStationId, setPackingStationId] = useState<string>('')
    const [scannedItemUpc, setScannedItemUpc] = useState<string>("")

    const [itemOperationResult, setItemOperationResult] = useState<ItemOperationResult>(ItemOperationResult.emptyResult())
    const [currentNumScans, setCurrentNumScans] = useState<number>(0);

    let itemManagerRef = useRef({} as ItemManager);

    useEffect(() => {
        if (authSuccessful(authUser, authPending)) {
            let itemManager = new ItemManager(
                setShipments,
                setLoading,
                setItemOperationResult,
                setScannedItemUpc,
                authUser
            )

            if (packingStationId === '') {
                itemManagerRef.current = itemManager;
                const packingStationId = itemManager.getAuthService().getPackingStationId()

                setPackingStationId(packingStationId);
                setLoading(true);

                backendCall('/get-solution-details', {
                    is_test: '0',
                    solution_id: solutionId,
                }, r => r).then((r: any) => {

                    try {
                        const rawResult = r['result']

                        const theoretical_shipment_assignment = r['result']['theoretical_shipment_assignment']

                        if (theoretical_shipment_assignment['status_code'] && theoretical_shipment_assignment['status_code'] !== 200) {
                            let errorMessage = theoretical_shipment_assignment['exception']
                            console.log(errorMessage)
                            alert(errorMessage)
                            setShipments([])
                        } else {

                            // Adding an array for item scan progress
                            setSolutionData(rawResult)
                            setPackingStationId(itemManagerRef.current.getAuthService().getPackingStationId())
                            itemManagerRef.current.setSolutionBody(rawResult);
                            itemManagerRef.current.initialize();
                            itemManagerRef.current.getPackingStationService().restartLights()

                            // Setting Metadata about the solution
                            itemManagerRef.current.setOrderId(theoretical_shipment_assignment.tote_data.order_id)
                            itemManagerRef.current.setToteId(theoretical_shipment_assignment.tote_data.tote_id)
                            itemManagerRef.current.setRequestId(rawResult.request_id)
                            itemManagerRef.current.setSolutionId(rawResult.solution_id)
                        }

                    } catch (e) {
                        console.log(e)
                        alert(e)
                        setShipments([])
                    }

                    setLoading(false);


                });
            }
        }
    }, [authPending, authUser, packingStationId, solutionId])

    const handleOperationSelection = (e: any) => {
        const selection = e.key
        setSelectedPackingOperation(selection);
        setCurrentNumScans(0);
    }

    // This way we are letting the child components handle the ENTER key press
    const handleKeyPress = (e: any) => {

        if (e.key === 'Enter') {
            e.preventDefault()
            e.stopPropagation()

            setCurrentNumScans(currentNumScans + 1);
        }
    }

    const handleLightRestart = () => {
        setLoading(true);
        itemManagerRef.current.getPackingStationService().restartLights((data: any) => {
            setLoading(false);
        })
    }

    const handleScanButtonClick = () => {
        setCurrentNumScans(currentNumScans + 1);
    }


    return (
        <div
            onKeyPress={handleKeyPress}
            tabIndex={0}
            style={{
                width: "90%",
                marginLeft: "5%",
                marginTop: "10px",
                textAlign: "center"
            }}
        >


            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >

                <div
                    style={{
                        width: "20%",
                        border: "1px solid black",
                        borderRadius: "10px",
                    }}
                >
                    <div>
                        <ToteInfoView
                            solutionData={solutionData.theoretical_shipment_assignment}
                            loading={loading}
                            authService={new AuthService(authUser)}
                        >

                        </ToteInfoView>

                    </div>
                </div>
                <div
                    style={{
                        width: "25%",
                        border: "1px solid black",
                        borderRadius: "10px",

                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: "1px solid #1890ff",
                            paddingLeft: "1%",
                            paddingRight: "1%",
                            paddingTop: "1.75%",
                            marginBottom: "3%",
                            height: "57px",

                        }}
                    >

                        <div>
                            <Menu
                                selectedKeys={[selectedPackingOperation]}
                                mode="horizontal"
                                onClick={handleOperationSelection}
                            >
                                <Menu.Item key={ItemOperationEnum.SCAN_ITEM}>
                                    <Icon type="enter"/>
                                    {bold('Scan Item')}
                                </Menu.Item>
                                <Menu.Item key={ItemOperationEnum.MOVE_ITEM}>
                                    <Icon type="rollback"/>
                                    {bold('Move Item')}
                                </Menu.Item>
                            </Menu>
                        </div>


                        <div
                            style={{
                                marginTop: "2.5%",
                                marginRight: "1%"
                            }}
                        >
                            <Checkbox
                                checked={focusToScanInput}
                                value={focusToScanInput}
                                onChange={(e) => {
                                    setFocusToScanInput(e.target.checked)
                                }}
                            >{bold('Jump')}</Checkbox>
                        </div>

                    </div>

                    <ItemOperationRouter
                        itemOperationEnum={selectedPackingOperation}
                        itemManager={itemManagerRef.current}
                        setScannedItemUpc={setScannedItemUpc}
                        scannedItemUpc={scannedItemUpc}
                        focusToScanInput={focusToScanInput}
                        packingStationId={packingStationId}
                        loading={loading}
                        currentNumScans={currentNumScans}
                    />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            marginTop: "2%",
                            marginBottom: "2%",
                            width: '90%',
                            marginLeft: '5%',
                        }}
                    >
                        <div>
                            <Button
                                type={"danger"}
                                onClick={handleLightRestart}
                                loading={loading}
                                disabled={loading}
                            >
                                Restart Lights <Icon type="bulb"/>
                            </Button>
                        </div>
                        <div>
                            <Button
                                type={"primary"}
                                onClick={handleScanButtonClick}
                                loading={loading}
                                disabled={loading}
                            >
                                Scan Item <Icon type="barcode"/>
                            </Button>
                        </div>
                    </div>

                </div>
                <div
                    style={{
                        width: "50%",
                        border: "1px solid black",
                        borderRadius: "10px",
                    }}
                >
                    <div>
                        <ScanResultView
                            itemOperationResult={itemOperationResult}
                            loading={loading}
                        >

                        </ScanResultView>
                    </div>
                </div>

            </div>

            <div
                style={{
                    marginTop: "10px",
                    textAlign: "center",
                }}
            >
                {
                    (shipments.length === 0 && loading) === true ?
                        <Spin/>
                        :
                        <Table
                            dataSource={shipments}
                            columns={getShipmentTableColumns(
                                itemManagerRef.current,
                                packingStationId
                            )}
                            loading={shipments.length > 0 && loading}
                            pagination={{
                                pageSize: 30,
                            }}
                        />
                }
            </div>

        </div>

    )

}

export default withRouter(PackSolutionContainer);

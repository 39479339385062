import {Button, Collapse} from "antd";
import React from "react";
import {columnTitle} from "../../API/Utils";

const {Panel} = Collapse;


export const OLD_SHIPMENT_TABLE_COLUMNS = [
    {
        title: columnTitle('id', ''),
        dataIndex: 'shipment_id',
        key: 'shipment_id',
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
        sorter: (a: any, b: any) => a.shipment_id - b.shipment_id,
        render: (text: any, record: any) => {
            return <div>
                {+record.shipment_id < 1000 ? <span style={{fontWeight: 'bold'}}>{record.shipment_id}</span> :
                    <a
                        href={`https://med.deposco.com/deposco/entity/detail;id=${record.shipment_id};bk=${record.shipment_id};entity=Shipment;tabIndex=0;currentLayoutId=undefined`}
                        rel={"noopener noreferrer"}
                        target={"_blank"}
                        style={{
                            fontWeight: 'bold'
                        }}
                    ><Button
                        type={'primary'}
                    >
                        {record.shipment_id}
                    </Button>
                    </a>
                }
            </div>
        }

    },
    {
        title: columnTitle('weight', ''),
        dataIndex: 'cargo_weight',
        key: 'cargo_weight',
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
        sorter: (a: any, b: any) => a.cargo_weight - b.cargo_weight,
    },
    {
        title: columnTitle('volume', ''),
        dataIndex: 'cargo_volume',
        key: 'cargo_volume',
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
        sorter: (a: any, b: any) => a.cargo_volume - b.cargo_volume,
    },
    {
        title: columnTitle('new_ship_via', ''),
        dataIndex: 'ship_via_string',
        key: 'ship_via_string',
        render: (text: any, record: any) => {
            return (
                <div
                    style={{
                        textAlign: 'center'
                    }}
                >
                    <div>
                        <span style={{fontWeight: 'bold'}}>{text}</span>
                    </div>
                </div>
            )
        }
    },
    {
        title: columnTitle('cost_breakdown', ''),
        dataIndex: 'shipping_cost',
        key: 'shipping_cost',
        width: '20%',
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
        sorter: (a: any, b: any) => a.shipping_cost - b.shipping_cost,
        render: (text: any, record: any) => {
            return (
                <div>
                    <Collapse>
                        <Panel header={`$${text} `} key="1" style={{}}>
                            <div>
                                {record.cost_breakdown.map((item: any, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                marginTop: "2%",
                                                marginBottom: "2%"
                                            }}
                                        >
                                            <div><span style={{fontWeight: 'bold'}}> {item[0]}</span></div>
                                            <div>= {item[1]}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Panel>
                    </Collapse>

                </div>
            )
        }
    },
    {
        title: columnTitle('item_quantities', ''),
        dataIndex: 'item_quantities',
        key: 'item_quantities',
        width: '30%',
        render: (items: any) => {
            let num_items = 0;
            for (let i = 0; i < items.length; i++) {
                num_items += items[i][1]
            }

            return (
                <div>
                    <Collapse>
                        <Panel header={num_items} key="1" style={{}}>
                            <div>
                                {items.map((item: any, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                marginTop: "2%",
                                                cursor: "pointer",
                                                zIndex: 1000

                                            }}
                                        >
                                            <a
                                                href={`https://medusadistribution.com/search?q=${item[0]}`}
                                                target={'_blank'}
                                            >
                                                <Button
                                                    type={'danger'}
                                                >{item[1]} x {item[0]}</Button>
                                            </a>
                                        </div>
                                    )
                                })}
                            </div>
                        </Panel>
                    </Collapse>

                </div>
            )
        }

    }

];
